import React, { useState } from "react";
import styled from "styled-components";
import After_Hours_Logo from "../../Assets/Logo/After Hours-Logo_final.png";
import { NavLink } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
// import { responsive } from "../../Responsive/Responsive";

const OuterContainer = styled.div`
  border-bottom: 2px solid lightgray;
  overflow: hidden;
  background: white;
  position: fixed;
  z-index: 2;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  /* margin-left: 150px; */
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const Logo = styled.span`
  width: 80%;

  @media screen and (max-width: 800px) {
    width: 50%;
    margin-left: 10px;
  }
`;

const Img = styled.img`
  max-width: 50%;
  height: auto;
  margin: 5px 0px;
`;

const Middle = styled.div`
  flex: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const UnOrderList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;

const List = styled.li`
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 0 5px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  @media screen and (max-width: 980px) {
    font-size: 9px;
  }
`;

const Link = styled(NavLink)`
  text-decoration: none;
  color: black;
  padding: 5px;
  border-radius: 3px;

  &:hover {
    color: white;
    background-color: black;
    padding: 5px;
  }

  &.active {
    color: white;
    background-color: black;
    padding: 5px;
  }
`;

const FooterLink = styled.a`
  color: black;
  text-decoration: none;
  padding: 5px;
  border-radius: 3px;

  &.active {
    color: black;
    background-color: white;
    padding: 5px;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  @media screen and (max-width: 800px) {
    margin-right: 10px;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
`;

const Button = styled.button`
  border: none;
  font-size: 18px;
  color: blue;
  background: none;

  &:hover {
    color: green;
    cursor: pointer;
  }
`;
const Instagram = styled.span`
  font-size: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Youtube = styled.span`
  font-size: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HmaburgerMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin-right: 20px;
`;

const HamburgerIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 5px 7px;
  border: 2px solid rgb(66, 245, 84);
  color: rgb(66, 245, 84);
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

const HamburgerButton = styled.div`
  @media screen and (min-width: 800px) {
    margin-right: 90px;
  }
`;

const ExtandNavbar = styled.div`
  color: white;
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

const Main = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: #4f9543; ;
`;

const ExtraLinkContainer = styled.div`
  margin: 10px 0px;
`;

const Navbar = () => {
  const [hambugerMenu, setHambugerMenu] = useState(false);

  return (
    <OuterContainer>
      <InnerContainer>
        <Left>
          <Logo>
            <NavLink to="/">
              <Img src={After_Hours_Logo} />
            </NavLink>
          </Logo>
        </Left>

        <Middle>
          <UnOrderList>
            <List>
              <Link to="/">HOME</Link>
            </List>
            <List>
              <Link to="/about">ABOUT</Link>
            </List>
            <List>
              <Link to="/room">ROOM & SUITS</Link>
            </List>
            <List>
              <Link to="/facilities">FACILITIES</Link>
            </List>
            <List>
              <Link to="/wellness">WELLNESS</Link>
            </List>
            <List>
              <Link to="/restaurant">RESTAURANTS</Link>
            </List>
            <List>
              <Link to="/meeting">MEETING & EVENTS</Link>
            </List>
            <List>
              <FooterLink href="#footer">CONTACT</FooterLink>
            </List>
          </UnOrderList>
        </Middle>

        <Right>
          <Icon>
            <Button
              onClick={() => {
                window.open(
                  "https://www.facebook.com/AfterHoursBanani",
                  "_blank"
                );
              }}
            >
              <BsFacebook />
            </Button>
          </Icon>
          <Icon>
            <Button
              onClick={() => {
                window.open("http://instagram.com/afterhoursbanani", "_blank");
              }}
            >
              <Instagram>
                <AiFillInstagram />
              </Instagram>
            </Button>
          </Icon>
          <Icon>
            <Button
              onClick={() => {
                window.open("/*", "_blank");
              }}
            >
              <Youtube>
                <AiFillYoutube />
              </Youtube>
            </Button>
          </Icon>
        </Right>
        <HmaburgerMenu>
          <HamburgerButton
            onClick={() => {
              setHambugerMenu(!hambugerMenu);
            }}
          >
            <HamburgerIcon>
              <GiHamburgerMenu />
            </HamburgerIcon>
          </HamburgerButton>
        </HmaburgerMenu>
      </InnerContainer>

      <ExtandNavbar>
        {hambugerMenu && (
          <Main
            onClick={() => {
              setHambugerMenu(!hambugerMenu);
            }}
          >
            <ExtraLinkContainer>
              <Link to="/">HOME</Link>
            </ExtraLinkContainer>
            <ExtraLinkContainer>
              <Link to="/about">ABOUT</Link>
            </ExtraLinkContainer>
            <ExtraLinkContainer>
              <Link to="/room">ROOM & SUITS</Link>
            </ExtraLinkContainer>
            <ExtraLinkContainer>
              <Link to="/facilities">FACILITIES</Link>
            </ExtraLinkContainer>
            <ExtraLinkContainer>
              <Link to="/wellness">WELLNESS</Link>
            </ExtraLinkContainer>
            <ExtraLinkContainer>
              <Link to="/restaurant">RESTAURANTS</Link>
            </ExtraLinkContainer>
            <ExtraLinkContainer>
              <Link to="/meeting">MEETING & EVENTS</Link>
            </ExtraLinkContainer>
            <ExtraLinkContainer>
              <FooterLink href="#footer">CONTACT</FooterLink>
            </ExtraLinkContainer>
          </Main>
        )}
      </ExtandNavbar>
    </OuterContainer>
  );
};

export default Navbar;
