import React from "react";
import MainLandingComponent from "../Component/Body/LandingComponent/MainLandingComponent";

const LandingPage = () => {
  return (
    <div>
      <MainLandingComponent />
    </div>
  );
};

export default LandingPage;
