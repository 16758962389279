import styled from "styled-components";
import Image1 from "../../../Assets/Landging_img/MiddleLandingImg1.jpg";
import Image2 from "../../../Assets/Landging_img/MiddleLandingImg2.jpg";
import { responsive } from "../../../Responsive/Responsive";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 40px 140px;

  ${responsive({
    margin: "20px 10px",
  })}
`;

const WelcomeText = styled.h1`
  color: rgb(75, 245, 66);
  text-align: center;
  ${responsive({
    fontSize: "22px",
  })}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  ${responsive({
    flexDirection: "column",
    margin: "5px",
  })}
`;

const Left = styled.div`
  flex: 1;
  text-align: justify;
  word-spacing: 3px;
  margin: 15px;
  line-height: 30px;
  word-spacing: 1px !important;
  ${responsive({
    margin: "5px",
    lineHeight: "25px",
  })}
`;

const HotelName = styled.span`
  font-weight: 600;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  ${responsive({
    flexDirection: "column",
  })}
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 5px;
`;

const ImageContainerOne = styled.div`
  margin-top: 40px;
  box-shadow: 3px 3px 3px 3px gray;
  ${responsive({
    // height: "300px",
    // width: "100%",
    margin: "20px 10px",
  })}
`;

const ImageContainerTwo = styled.div`
  border: 1px solid gray;
  box-shadow: 3px 3px 3px 3px 3px gray;
  margin-bottom: 55px;
  margin-left: 10px;
  ${responsive({
    // height: "300px",
    // width: "100%",
    margin: " 10px",
  })}
`;

const BottomText = styled.div`
  text-align: justify;
  margin: 20px 30px;
  word-spacing: 1px !important;
  line-height: 30px;
  ${responsive({
    margin: "10px 20px",
    lineHeight: "25px",
  })}
`;

const MiddleLandingComponent = () => {
  AnimationOnScroll();
  return (
    <Container>
      <WelcomeText data-aos="fade-up" data-aos-duration="2000">
        WELCOME TO AFTER HOURS
      </WelcomeText>
      <Wrapper>
        <Left>
          <HotelName>After Hours Residence</HotelName>, a 4-star hotel is
          conveniently located in the diplomatic area of Banani and Gulshan in
          Dhaka, Bangladesh. On the edge of beautiful Gulshan lake and the next
          to the American Club. It is only 10 minutes’ drive from one of the
          best shopping areas in Dhaka and 20 minutes’ drive from the Hazrat
          Shahjalal International Airport, Dhaka. As our valued guest we are
          committed to create an environment of security and wellbeing
          throughout your stay. To ensure safety of our guests, the entire hotel
          is equipped with modern firefighting system. There are round the clock
          security guards and CCTV monitoring also.
        </Left>
        <Right>
          <ImageContainerOne data-aos="fade-up" data-aos-duration="2000">
            <Img loading="lazy" src={Image1} data-aos="flip-left" />
          </ImageContainerOne>
          <ImageContainerTwo data-aos="fade-down" data-aos-duration="2000">
            <Img loading="lazy" src={Image2} data-aos="flip-right" />
          </ImageContainerTwo>
        </Right>
      </Wrapper>
      <BottomText>
        The hotel features distinctive western architecture with 46
        well-appointed rooms and suites. Our all-day-dining restaurant offers an
        interactive buffet-style concept and a creative chart menu. All our
        rooms are one of its kind, featuring contemporary style bedrooms,
        luscious bathrooms and is crusted with the most modern room amenities
        and facilities complemented by breathtaking interior.
      </BottomText>
    </Container>
  );
};

export default MiddleLandingComponent;
