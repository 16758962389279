import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TopImg from "../../../Assets/Meeting_Page_img/TopImg.jpg";
import MiddleImg from "../../../Assets/Meeting_Page_img/MiddleImg.jpg";
import GalleryImg1 from "../../../Assets/Meeting_Page_img/Gallery1.jpg";
import GalleryImg2 from "../../../Assets/Meeting_Page_img/Gallery2.jpg";
import GalleryImg3 from "../../../Assets/Meeting_Page_img/Gallery3.jpg";
import GalleryImg4 from "../../../Assets/Meeting_Page_img/Gallery4.jpg";
import GalleryImg5 from "../../../Assets/Meeting_Page_img/Gallery5.jpg";
import GalleryImg6 from "../../../Assets/Meeting_Page_img/Gallery6.jpg";
import GalleryImg7 from "../../../Assets/Meeting_Page_img/Gallery7.jpg";
import GalleryImg8 from "../../../Assets/Meeting_Page_img/Gallery8.jpg";
import GalleryImg9 from "../../../Assets/Meeting_Page_img/Gallery9.jpg";
import GalleryImg10 from "../../../Assets/Meeting_Page_img/Gallery10.jpg";
import GalleryImg11 from "../../../Assets/Meeting_Page_img/Gallery11.jpg";
import GalleryImg12 from "../../../Assets/Meeting_Page_img/Gallery12.jpg";
import GalleryImg13 from "../../../Assets/Meeting_Page_img/Gallery13.jpg";
import GalleryImg14 from "../../../Assets/Meeting_Page_img/Gallery14.jpg";
import GalleryImg15 from "../../../Assets/Meeting_Page_img/Gallery15.jpg";
import { responsive } from "../../../Responsive/Responsive";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  overflow: hidden;
`;

const Top = styled.div`
  overflow: hidden;
  height: 100vh;
  ${responsive({
    height: "50vh",
  })}
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const Middle = styled.div`
  margin: 40px 145px;
  ${responsive({
    margin: "15px",
  })}
`;

const MiddleHeadingText = styled.h1`
  color: rgb(4, 201, 34);
  text-align: center;
  margin: 10px 0px;

  ${responsive({
    fontSize: "22px",
  })}
`;

const HeadingText = styled.h3`
  color: rgb(4, 201, 34);
  text-align: center;
  margin: 10px 0px;
  ${responsive({
    fontSize: "18px",
  })}
`;
const Text = styled.div`
  margin: 10px 20px;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    margin: "5px",
    lineHeight: "25px",
  })}
`;

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding: 20px 120px;
  ${responsive({
    flexDirection: "column",
    margin: "5px",
    padding: "20px 0px",
  })}
`;

const ImgContainer = styled.div`
  flex: 1;
  /* margin: 20px; */
  box-shadow: 2px 2px 2px 2px lightgray;
  ${responsive({
    height: "50vh",
  })}
`;

const MiddleImage = styled.img`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const MiddleTextRight = styled.div`
  flex: 1;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;

  ${responsive({
    margin: "10px 0px",
    lineHeight: "25px",
  })}
`;

const TableContainer = styled.div`
  margin-right: 40px;
  ${responsive({
    overflowX: "auto",
    margin: "0px 10px",
  })}
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid rgb(4, 201, 34);
  border-collapse: collapse;
  margin: 10px 20px;
  ${responsive({
    fontSize: "12px",
  })};
`;

const TableRow = styled.tr``;
const TableHeading = styled.th`
  border: 1px solid rgb(4, 201, 34);
  border-collapse: collapse;
  text-align: center;
`;
const TableData = styled.td`
  border: 1px solid rgb(4, 201, 34);
  border-collapse: collapse;
  text-align: center;
`;

const GalleryText = styled.h2`
  text-align: center;
  margin: 30px 0px;
  color: rgb(4, 201, 34);

  ${responsive({
    fontSize: "20px",
  })}
`;

const ImgGallery = styled.div`
  overflow: hidden;
  border: 1px solid rgb(4, 201, 34);
  margin: 50px 20px;

  ${responsive({
    margin: "0px 10px",
  })}
`;

const GalleryTop = styled.div`
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;

  ${responsive({
    flexDirection: "column",
  })}
`;

const GalleryBottom = styled.div`
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;

  ${responsive({
    flexDirection: "column",
  })}
`;

const GalleryImg = styled.img`
  width: 15%;
  height: 10%;
  margin: 5px;
  border: 5px;

  ${responsive({
    width: "90%",
    height: "50%",
  })}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MeetingEventsComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [hidePreviousArrow, setHidePreviousArrow] = useState(true);
  const [hideNextArrow, setHideNextArrow] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const pictures = [
    GalleryImg1,
    GalleryImg2,
    GalleryImg3,
    GalleryImg4,
    GalleryImg5,
    GalleryImg6,
    GalleryImg7,
    GalleryImg8,
    GalleryImg9,
    GalleryImg10,
    GalleryImg11,
    GalleryImg12,
    GalleryImg13,
    GalleryImg14,
    GalleryImg15,
  ];

  useEffect(() => {
    if (slideIndex === 0) {
      setHidePreviousArrow(true);
    } else {
      setHidePreviousArrow(false);
    }
    if (slideIndex === pictures.length - 1) {
      setHideNextArrow(true);
    } else {
      setHideNextArrow(false);
    }
  }, [slideIndex, pictures.length]);

  function handlePreviousArrow() {
    setSlideIndex(slideIndex - 1);
  }

  function handleNextArrow() {
    setSlideIndex(slideIndex + 1);
  }

  AnimationOnScroll();
  return (
    <Container>
      <Top>
        <Img src={TopImg} loading="lazy" />
      </Top>

      <Middle>
        <MiddleHeadingText data-aos="fade-up" data-aos-duration="2000">
          MEETING & EVENTS
        </MiddleHeadingText>
        <Text>
          The Hotel has Beverage outlets and meeting area branded as Corporate
          point. On top of that there is a function area where we can
          accommodate many people in different table set up for dinner or for
          any other events.
        </Text>

        <Wrapper>
          <ImgContainer>
            <MiddleImage src={MiddleImg} loading="lazy" />
          </ImgContainer>
          <MiddleTextRight>
            <HeadingText data-aos="fade-up" data-aos-duration="2000">
              CONFERENCE
            </HeadingText>
            <Text>
              The Conference Hall provides excellent facilities for medium size
              programs and events. Accommodating 130 people in theater style
              Conference will be the right choice for workshop, business and
              social events. Latest sound system, projector and Wi-If are
              available to make your function a grand success.
            </Text>
          </MiddleTextRight>
        </Wrapper>

        {/* Conference Hall at a Glance table */}
        <HeadingText data-aos="fade-up" data-aos-duration="2000">
          CONFERENCE HALL AT A GLANCE
        </HeadingText>

        <TableContainer>
          <Table>
            <TableRow>
              <TableHeading>Function Room</TableHeading>
              <TableHeading>Location</TableHeading>
              <TableHeading>Measurement (SFT)</TableHeading>
              <TableHeading>Theater Style</TableHeading>
              <TableHeading>Class Room</TableHeading>
              <TableHeading>U-shape</TableHeading>
              <TableHeading>Banquet</TableHeading>
              <TableHeading>Cocktail Reception</TableHeading>
            </TableRow>
            <TableRow>
              <TableData></TableData>
              <TableData> 1st FLOOR </TableData>
              <TableData></TableData>
              <TableData>100</TableData>
              <TableData>60-70</TableData>
              <TableData>60-70</TableData>
              <TableData>100</TableData>
              <TableData>120</TableData>
            </TableRow>
          </Table>
        </TableContainer>

        {/* Pricing Table */}
        <HeadingText data-aos="fade-up" data-aos-duration="2000">
          PRICING
        </HeadingText>

        <TableContainer>
          <Table>
            <TableRow>
              <TableHeading>Full Day Banquet Hall Rent</TableHeading>
              <TableHeading>Half Day Banquet Hall Rent</TableHeading>
            </TableRow>
            <TableRow>
              <TableData> BDT 30,000 ++</TableData>
              <TableData> BDT 20,000 ++</TableData>
            </TableRow>
            <TableRow>
              <TableData colSpan="2">
                Above rates are exclusive of 10% Service Charge & 15% VAT
              </TableData>
            </TableRow>
          </Table>
        </TableContainer>

        {/* First Gallery  */}
        <GalleryText data-aos="fade-up" data-aos-duration="2000">
          GALLERY
        </GalleryText>
        <ImgGallery>
          <GalleryTop>
            <GalleryImg
              src={pictures[0]}
              loading="lazy"
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(0);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[1]}
              loading="lazy"
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(1);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[2]}
              loading="lazy"
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(2);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[3]}
              loading="lazy"
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(3);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[4]}
              loading="lazy"
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(4);
              }}
              className="picture"
              alt="pic"
            />
          </GalleryTop>

          <GalleryBottom>
            <GalleryImg
              src={pictures[5]}
              loading="lazy"
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(5);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[6]}
              loading="lazy"
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(6);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[7]}
              loading="lazy"
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(7);
              }}
              className="picture"
              alt="pic"
            />
          </GalleryBottom>
        </ImgGallery>

        {/* VIP Conference Hall at a Glance Table */}
        <HeadingText>VIP CONFERENCE HALL AT A GLANCE</HeadingText>

        <TableContainer>
          <Table>
            <TableRow>
              <TableHeading>Function Room</TableHeading>

              <TableHeading>Location</TableHeading>

              <TableHeading>Measurement (SFT)</TableHeading>

              <TableHeading>Round Style</TableHeading>

              <TableHeading>Class Room</TableHeading>

              <TableHeading>U-shape</TableHeading>

              <TableHeading>Banquet</TableHeading>

              <TableHeading>Cocktail Reception</TableHeading>
            </TableRow>
            <TableRow>
              <TableData> </TableData>
              <TableData> 10th FLOOR</TableData>
              <TableData> </TableData>
              <TableData>10 </TableData>
              <TableData> </TableData>
              <TableData>10 </TableData>
              <TableData> </TableData>
              <TableData> </TableData>
            </TableRow>
          </Table>
        </TableContainer>

        {/* Pricing Table */}
        <HeadingText data-aos="fade-up" data-aos-duration="2000">
          PRICING
        </HeadingText>

        <TableContainer>
          <Table>
            <TableRow>
              <TableHeading>Full Day Meeting Room Rent</TableHeading>
              <TableHeading>Half Day Meeting Room Rent</TableHeading>
              <TableHeading>Meeting Room-Per Hour</TableHeading>
            </TableRow>
            <TableRow>
              <TableData> BDT 20,000 ++</TableData>
              <TableData> BDT 15,000 ++</TableData>
              <TableData> BDT 3,000 ++</TableData>
            </TableRow>
            <TableRow>
              <TableData colSpan="2">
                Above rates are exclusive of 10% Service Charge & 15% VAT
              </TableData>
            </TableRow>
          </Table>
        </TableContainer>

        <GalleryText data-aos="fade-up" data-aos-duration="2000">
          GALLERY
        </GalleryText>
        <ImgGallery>
          <GalleryTop>
            <GalleryImg
              loading="lazy"
              src={pictures[8]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(8);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              loading="lazy"
              src={pictures[9]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(9);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              loading="lazy"
              src={pictures[10]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(10);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              loading="lazy"
              src={pictures[11]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(11);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              loading="lazy"
              src={pictures[12]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(12);
              }}
              className="picture"
              alt="pic"
            />
          </GalleryTop>

          <GalleryBottom>
            <GalleryImg
              loading="lazy"
              src={pictures[13]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(13);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              loading="lazy"
              src={pictures[14]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(14);
              }}
              className="picture"
              alt="pic"
            />
          </GalleryBottom>
        </ImgGallery>
      </Middle>
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button
              onClick={() => setModalOpen(false)}
              className="close-button"
            >
              X
            </button>
            <img
              loading="lazy"
              src={pictures[slideIndex]}
              className="modal-picture"
            />
            <ButtonContainer>
              {!hidePreviousArrow && (
                <button
                  onClick={handlePreviousArrow}
                  className="previous-button"
                >
                  {" "}
                  <MdOutlineKeyboardArrowLeft />
                </button>
              )}

              {!hideNextArrow && (
                <button onClick={handleNextArrow} className="next-button">
                  {" "}
                  <MdOutlineKeyboardArrowRight />
                </button>
              )}
            </ButtonContainer>
          </div>
        </div>
      )}
    </Container>
  );
};

export default MeetingEventsComponent;
