import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import Image1 from "../../../Assets/Landging_img/TopLandingImg1.jpg";
import Image2 from "../../../Assets/Landging_img/TopLandingImg2.jpg";
import Image3 from "../../../Assets/Landging_img/TopLandingImg3.jpg";
import Image4 from "../../../Assets/Landging_img/TopLandingImg4.jpg";
import Image5 from "../../../Assets/Landging_img/TopLandingImg5.jpg";
import Image6 from "../../../Assets/Landging_img/TopLandingImg6.jpg";
import Image7 from "../../../Assets/Landging_img/TopLandingImg7.jpg";

const ImgContainer = styled.div``;
export default function TopLandingComponent() {
  return (
    <div class="carousel-wrapper">
      <Carousel
        infiniteLoop
        useKeyboardArrows
        autoPlay
        showArrows={false}
        showIndicators={false}
        stopOnHover={false}
        showStatus={false}
      >
        <ImgContainer>
          <img loading="lazy" src={Image1} alt="After_Hours_Residence_Img" />
        </ImgContainer>
        <ImgContainer>
          <img loading="lazy" src={Image2} alt="After_Hours_Residence_Img" />
        </ImgContainer>
        <ImgContainer>
          <img loading="lazy" src={Image3} alt="After_Hours_Residence_Img" />
        </ImgContainer>
        <ImgContainer>
          <img loading="lazy" src={Image4} alt="After_Hours_Residence_Img" />
        </ImgContainer>
        <ImgContainer>
          <img loading="lazy" src={Image5} alt="After_Hours_Residence_Img" />
        </ImgContainer>
        <ImgContainer>
          <img loading="lazy" src={Image6} alt="After_Hours_Residence_Img" />
        </ImgContainer>
        <ImgContainer>
          <img loading="lazy" src={Image7} alt="After_Hours_Residence_Img" />
        </ImgContainer>
      </Carousel>
    </div>
  );
}
