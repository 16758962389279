import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MdLocalOffer } from "react-icons/md";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import Delux from "../../../Assets/Room_Page_img/delux.jpg";
import Delux1 from "../../../Assets/Room_Page_img/delux1.jpg";
import Delux2 from "../../../Assets/Room_Page_img/delux2.jpg";
import Delux3 from "../../../Assets/Room_Page_img/delux3.jpg";
import Delux4 from "../../../Assets/Room_Page_img/delux4.jpg";
import Delux5 from "../../../Assets/Room_Page_img/delux5.jpg";
import Delux6 from "../../../Assets/Room_Page_img/delux6.jpg";
import Delux7 from "../../../Assets/Room_Page_img/delux7.jpg";
import premium_delux from "../../../Assets/Room_Page_img/premium delux.jpg";
import premium_delux1 from "../../../Assets/Room_Page_img/premium delux1.jpg";
import premium_delux2 from "../../../Assets/Room_Page_img/premium delux2.jpg";
import premium_delux3 from "../../../Assets/Room_Page_img/premium delux3.jpg";
import premium_delux4 from "../../../Assets/Room_Page_img/premium delux4.jpg";
import premium_delux5 from "../../../Assets/Room_Page_img/premium delux5.jpg";
import premium_delux6 from "../../../Assets/Room_Page_img/premium delux6.jpg";
import premium_delux7 from "../../../Assets/Room_Page_img/premium delux7.jpg";
import permium_twin from "../../../Assets/Room_Page_img/permium twin.jpg";
import permium_twin1 from "../../../Assets/Room_Page_img/permium twin1.jpg";
import permium_twin2 from "../../../Assets/Room_Page_img/permium twin2.jpg";
import permium_twin3 from "../../../Assets/Room_Page_img/permium twin3.jpg";
import permium_twin4 from "../../../Assets/Room_Page_img/permium twin4.jpg";
import permium_twin5 from "../../../Assets/Room_Page_img/permium twin5.jpg";
import permium_twin6 from "../../../Assets/Room_Page_img/permium twin6.jpg";
import permium_twin7 from "../../../Assets/Room_Page_img/permium twin7.jpg";
import premium_suites from "../../../Assets/Room_Page_img/premium suites.jpg";
import premium_suites1 from "../../../Assets/Room_Page_img/premium suites1.jpg";
import premium_suites2 from "../../../Assets/Room_Page_img/premium suites2.jpg";
import premium_suites3 from "../../../Assets/Room_Page_img/premium suites3.jpg";
import premium_suites4 from "../../../Assets/Room_Page_img/premium suites4.jpg";
import premium_suites5 from "../../../Assets/Room_Page_img/premium suites5.jpg";
import premium_suites6 from "../../../Assets/Room_Page_img/premium suites6.jpg";
import premium_suites7 from "../../../Assets/Room_Page_img/premium suites7.jpg";
import { responsive } from "../../../Responsive/Responsive";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  padding-top: 120px;
  overflow: hidden;

  ${responsive({
    paddingTop: "80px",
  })}
`;

const Heading = styled.h1`
  text-align: center;
  color: rgb(75, 245, 66);
  ${responsive({
    fontSize: "22px",
  })}
`;

const DeluxBox = styled.div`
  padding: 10px;
  margin: 20px 165px;
  background-color: rgb(237, 228, 228);
  border-radius: 10px;
  ${responsive({
    margin: "20px 25px",
  })}
`;

const Top = styled.div`
  display: flex;
  ${responsive({
    flexDirection: "column",
  })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const ImgContainer = styled.div`
  margin: 5px 10px;
  box-shadow: 3px 3px 3px 3px lightgray;

  ${responsive({
    display: "flex",
    flexDirection: "column",
    margin: "5px",
    height: "40vh",
  })}
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  padding: 15px;
  margin-top: 10px;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    padding: "0px",
  })}
`;

const PremiumTop = styled.div`
  display: flex;

  ${responsive({
    flexDirection: "column",
  })}
`;

const TextHeading = styled.h2`
  text-align: center;
  color: rgb(75, 245, 66);
  margin: 0px;
`;
const Text = styled.div`
  padding: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OfferConatiner = styled.div`
  margin: 20px 30px;
  border: 1px solid rgb(75, 245, 66);
  border-radius: 10px;
  color: white;
  font-size: 14px;
  background: blue;
  ${responsive({
    margin: "10px",
  })}
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-size: 14px;

  ${responsive({
    padding: "4px",
    fontSize: "10px",
  })}
`;

const PriceContainer = styled.div`
  margin: 20px 30px;
  display: flex;

  ${responsive({
    margin: "10px",
  })}
`;

const Price = styled.h2`
  color: rgb(75, 245, 66);
  ${responsive({
    fontSize: "18px",
  })}
`;
const SmallText = styled.sub``;

const Middle = styled.div``;

const MiddleImgContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  ${responsive({
    height: "40vh",
  })}
`;

const MiddleImg = styled.img`
  width: 10%;
  height: 10%;
  margin: 10px;
  border-radius: 5px;
  ${responsive({
    margin: "5px",
  })}
`;

const ListContainer = styled.div`
  display: flex;
  margin: 10px;

  ${responsive({
    margin: "20px",
    flexDirection: "column",
  })}
`;

const MiddleLeft = styled.div`
  flex: 2;
  padding: 10px;
`;

const IconTextContainer = styled.div`
  display: flex;
`;

const Icon = styled.span`
  color: rgb(4, 201, 34);
  padding: 0px 10px;
`;

const MiddleText = styled.div``;

const MiddleCenter = styled.div`
  flex: 1;
  padding: 10px;
`;

const MiddleRight = styled.div`
  flex: 1;
  padding: 10px;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;

  ${responsive({
    margin: "20px",
  })}
`;

const BookButton = styled.button`
  padding: 15px 100px;
  border: none;
  color: white;
  background-color: blue;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    color: aqua;
    opacity: 0.5;
  }

  ${responsive({
    padding: "10px 60px",
    fontSize: "14px",
  })}
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
`;

const PremiumDeluxBox = styled.div`
  padding: 10px;
  margin: 30px 165px;
  background-color: rgb(237, 228, 228);
  border-radius: 10px;
  ${responsive({
    margin: "25px",
  })}
`;

const PremiumLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  padding: 20px;
  word-spacing: 1px;
  line-height: 30px;
  ${responsive({
    padding: "0px",
  })}
`;

const PremiumRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
`;

const PremiumTwinBox = styled.div`
  padding: 10px;
  margin: 30px 165px;
  background-color: rgb(237, 228, 228);
  border-radius: 10px;
  ${responsive({
    margin: "25px",
  })}
`;

const PremiumSuitsBox = styled.div`
  padding: 10px;
  margin: 30px 165px;
  background-color: rgb(237, 228, 228);
  border-radius: 10px;
  ${responsive({
    margin: "25px",
  })}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RoomSuitComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [hidePreviousArrow, setHidePreviousArrow] = useState(true);
  const [hideNextArrow, setHideNextArrow] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const pictures = [
    Delux1,
    Delux2,
    Delux3,
    Delux4,
    Delux5,
    Delux6,
    Delux7,
    premium_delux1,
    premium_delux2,
    premium_delux3,
    premium_delux4,
    premium_delux5,
    premium_delux6,
    premium_delux7,
    permium_twin1,
    permium_twin2,
    permium_twin3,
    permium_twin4,
    permium_twin5,
    permium_twin6,
    permium_twin7,
    premium_suites1,
    premium_suites2,
    premium_suites3,
    premium_suites4,
    premium_suites5,
    premium_suites6,
    premium_suites7,
  ];

  useEffect(() => {
    if (slideIndex === 0) {
      setHidePreviousArrow(true);
    } else {
      setHidePreviousArrow(false);
    }
    if (slideIndex === pictures.length - 1) {
      setHideNextArrow(true);
    } else {
      setHideNextArrow(false);
    }
  }, [slideIndex, pictures.length]);

  function handlePreviousArrow() {
    setSlideIndex(slideIndex - 1);
  }

  function handleNextArrow() {
    setSlideIndex(slideIndex + 1);
  }

  AnimationOnScroll();
  return (
    <Container>
      <Heading data-aos="fade-up" data-aos-duration="2000">
        Rooms & Suits
      </Heading>
      {/* DELUXE */}
      <DeluxBox id="delux">
        <Top>
          <Left>
            <ImgContainer>
              <Img loading="lazy" src={Delux} />
            </ImgContainer>
          </Left>
          <Right>
            <TextHeading data-aos="fade-up" data-aos-duration="2000">
              DELUXE
            </TextHeading>
            <Text>
              Indulge in the magical charm of our warm welcome, it will give you
              a homely feeling! Deluxe rooms of After Hours Residence Dhaka have
              trendy interiors combining international standard amenities and
              excellent services in a single knot. Averaging 212 square feet.
              each, these well-appointed Deluxe room are specially featured with
              fixed glazed windows, rainfall shower and signature bathroom
              amenities. Other special features include a Queen heavenly bed,
              work desk, cozy chair and wardrobe with drawers. With exotic
              furnishing and congenial amenities, enjoy your mesmerizing moments
              at Dhaka.
            </Text>
          </Right>
        </Top>
        <Wrapper>
          <OfferConatiner>
            <Button>
              <MdLocalOffer />
              Special Offer 10% Off
            </Button>
          </OfferConatiner>
          <PriceContainer>
            <Price>$ 120</Price>
            <SmallText>++/night</SmallText>
          </PriceContainer>
        </Wrapper>
        <Middle>
          <MiddleImgContainer data-aos="fade-up" data-aos-duration="2000">
            <MiddleImg
              loading="lazy"
              src={pictures[0]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(0);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[1]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(1);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[2]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(2);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[3]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(3);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[4]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(4);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[5]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(5);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[6]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(6);
              }}
              className="picture"
              alt="pic"
            />
          </MiddleImgContainer>

          <ListContainer>
            <MiddleLeft>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Fully air-conditioned rooms & suites</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Tea/coffee making facility in all room</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Free Wi-Fi</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Safe deposit box</MiddleText>
              </IconTextContainer>
            </MiddleLeft>
            <MiddleCenter>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Iron & Iron board (on request)</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>24- hour room service</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Flat TV with 90 chaneles</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>IDD telephone</MiddleText>
              </IconTextContainer>
            </MiddleCenter>
            <MiddleRight>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Hot & cold water</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Mini bar & hair dryer in all room</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Smoking & Non- Smoking rooms</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Daily newspaper</MiddleText>
              </IconTextContainer>
            </MiddleRight>
          </ListContainer>
        </Middle>
        <Bottom>
          <BookButton>
            <Link href="https://web.whatsapp.com/send/?phone=+8801894868680">
              BOOK NOW
            </Link>
          </BookButton>
        </Bottom>
      </DeluxBox>
      {/* PREMIUM DELUXE */}
      <section id="premiumdelux">
        <PremiumDeluxBox>
          <PremiumTop>
            <PremiumLeft>
              <TextHeading data-aos="fade-up" data-aos-duration="2000">
                PREMIUM DELUXE
              </TextHeading>
              <Text>
                After Hours Residence Dhaka have trendy interiors combining
                international standard amenities and excellent services in a
                single knot. Averaging 253-280 square feet each, these
                well-appointed Premier deluxe are specially featured with fixed
                glazed windows, rainfall shower and signature bathroom
                amenities. Rooms have either 10 Queen bed or Fifteen twin beds,
                work desk, cozy chair and wardrobe with drawers. With exotic
                furnishing and congenial amenities, enjoy your mesmerizing
                moments at Dhaka.
              </Text>
            </PremiumLeft>
            <PremiumRight>
              <ImgContainer>
                <Img src={premium_delux} />
              </ImgContainer>
            </PremiumRight>
          </PremiumTop>
          <Wrapper>
            <OfferConatiner>
              <Button>
                <MdLocalOffer />
                Special Offer 20% Off
              </Button>
            </OfferConatiner>
            <PriceContainer>
              <Price>$ 150</Price>
              <SmallText>++/night</SmallText>
            </PriceContainer>
          </Wrapper>
          <Middle>
            <MiddleImgContainer data-aos="fade-up" data-aos-duration="2000">
              <MiddleImg
                loading="lazy"
                src={pictures[7]}
                onClick={() => {
                  setModalOpen(true);
                  setSlideIndex(7);
                }}
                className="picture"
                alt="pic"
              />
              <MiddleImg
                loading="lazy"
                src={pictures[8]}
                onClick={() => {
                  setModalOpen(true);
                  setSlideIndex(8);
                }}
                className="picture"
                alt="pic"
              />
              <MiddleImg
                loading="lazy"
                src={pictures[9]}
                onClick={() => {
                  setModalOpen(true);
                  setSlideIndex(9);
                }}
                className="picture"
                alt="pic"
              />
              <MiddleImg
                loading="lazy"
                src={pictures[10]}
                onClick={() => {
                  setModalOpen(true);
                  setSlideIndex(10);
                }}
                className="picture"
                alt="pic"
              />
              <MiddleImg
                loading="lazy"
                src={pictures[11]}
                onClick={() => {
                  setModalOpen(true);
                  setSlideIndex(11);
                }}
                className="picture"
                alt="pic"
              />
              <MiddleImg
                loading="lazy"
                src={pictures[12]}
                onClick={() => {
                  setModalOpen(true);
                  setSlideIndex(12);
                }}
                className="picture"
                alt="pic"
              />
              <MiddleImg
                loading="lazy"
                src={pictures[13]}
                onClick={() => {
                  setModalOpen(true);
                  setSlideIndex(13);
                }}
                className="picture"
                alt="pic"
              />
            </MiddleImgContainer>

            <ListContainer>
              <MiddleLeft>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>Fully air-conditioned rooms & suites</MiddleText>
                </IconTextContainer>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>
                    Tea/coffee making facility in all room
                  </MiddleText>
                </IconTextContainer>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>Free Wi-Fi</MiddleText>
                </IconTextContainer>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>Safe deposit box</MiddleText>
                </IconTextContainer>
              </MiddleLeft>
              <MiddleCenter>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>Iron & Iron board (on request)</MiddleText>
                </IconTextContainer>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>24- hour room service</MiddleText>
                </IconTextContainer>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>Flat TV with 90 chaneles</MiddleText>
                </IconTextContainer>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>IDD telephone</MiddleText>
                </IconTextContainer>
              </MiddleCenter>
              <MiddleRight>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>Hot & cold water</MiddleText>
                </IconTextContainer>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>Mini bar & hair dryer in all room</MiddleText>
                </IconTextContainer>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>Smoking & Non- Smoking rooms</MiddleText>
                </IconTextContainer>
                <IconTextContainer>
                  <Icon>
                    <BsFillArrowRightCircleFill />
                  </Icon>
                  <MiddleText>Daily newspaper</MiddleText>
                </IconTextContainer>
              </MiddleRight>
            </ListContainer>
          </Middle>
          <Bottom>
            <BookButton>
              <Link href="https://web.whatsapp.com/send/?phone=+8801894868680">
                BOOK NOW
              </Link>
            </BookButton>
          </Bottom>
        </PremiumDeluxBox>
      </section>
      {/* PREMIUM TWIN */}
      <PremiumTwinBox>
        <Top>
          <Left>
            <ImgContainer>
              <Img src={permium_twin} />
            </ImgContainer>
          </Left>
          <Right>
            <TextHeading data-aos="fade-up" data-aos-duration="2000">
              PREMIUM TWIN
            </TextHeading>
            <Text>
              After Hours Residence Dhaka have trendy interiors combining
              international standard amenities and excellent services in a
              single knot. Averaging 216-280 square feet each, these
              well-appointed Premier deluxe are specially featured with fixed
              glazed windows, rainfall shower and signature bathroom amenities.
              Rooms have either Thirteen (13) twin beds, work desk, cozy chair
              and wardrobe with drawers. With exotic furnishing and congenial
              amenities, enjoy your mesmerizing moments at Dhaka.
            </Text>
          </Right>
        </Top>

        <Wrapper>
          <OfferConatiner>
            <Button>
              <MdLocalOffer />
              Special Offer 30% Off
            </Button>
          </OfferConatiner>
          <PriceContainer>
            <Price>$ 170</Price>
            <SmallText>++/night</SmallText>
          </PriceContainer>
        </Wrapper>
        <Middle>
          <MiddleImgContainer data-aos="fade-up" data-aos-duration="2000">
            <MiddleImg
              loading="lazy"
              src={pictures[14]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(14);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[15]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(15);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[16]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(16);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[17]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(17);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[18]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(18);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[19]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(19);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              loading="lazy"
              src={pictures[20]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(20);
              }}
              className="picture"
              alt="pic"
            />
          </MiddleImgContainer>

          <ListContainer>
            <MiddleLeft>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Fully air-conditioned rooms & suites</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Tea/coffee making facility in all room</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Free Wi-Fi</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Safe deposit box</MiddleText>
              </IconTextContainer>
            </MiddleLeft>
            <MiddleCenter>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Iron & Iron board (on request)</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>24- hour room service</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Flat TV with 90 chaneles</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>IDD telephone</MiddleText>
              </IconTextContainer>
            </MiddleCenter>
            <MiddleRight>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Hot & cold water</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Mini bar & hair dryer in all room</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Smoking & Non- Smoking rooms</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Daily newspaper</MiddleText>
              </IconTextContainer>
            </MiddleRight>
          </ListContainer>
        </Middle>
        <Bottom>
          <BookButton>
            <Link href="https://web.whatsapp.com/send/?phone=+8801894868680">
              BOOK NOW
            </Link>
          </BookButton>
        </Bottom>
      </PremiumTwinBox>

      {/* PREMIUM SUITES */}

      <PremiumSuitsBox>
        <PremiumTop>
          <PremiumLeft>
            <TextHeading data-aos="fade-up" data-aos-duration="1000">
              PREMIUM SUITES
            </TextHeading>
            <Text>
              Premium Suite Room is perfect for the personalities that require
              an air of opulence around the environment. Three (3) large suits
              are designed using sophisticated interior and luxurious
              furnishings so that you can feel cozy and homely. Fine quality
              room amenities are merged with amiable room services, exciting
              dining options, meeting facilities and many more. Ideal for
              business travelers, couples or families. Our immaculate suit plan
              and world-class services are aimed at your total satisfaction. Get
              yourself pampered by peaceful and homely feeling.
            </Text>
          </PremiumLeft>
          <PremiumRight>
            <ImgContainer>
              <Img src={premium_suites} />
            </ImgContainer>
          </PremiumRight>
        </PremiumTop>
        <Wrapper>
          <OfferConatiner>
            <Button>
              <MdLocalOffer />
              Special Offer 40% Off
            </Button>
          </OfferConatiner>
          <PriceContainer>
            <Price>$ 200</Price>
            <SmallText>++/night</SmallText>
          </PriceContainer>
        </Wrapper>
        <Middle>
          <MiddleImgContainer data-aos="fade-up" data-aos-duration="1000">
            <MiddleImg
              src={pictures[21]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(21);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              src={pictures[22]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(22);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              src={pictures[23]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(23);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              src={pictures[24]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(24);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              src={pictures[25]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(25);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              src={pictures[26]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(26);
              }}
              className="picture"
              alt="pic"
            />
            <MiddleImg
              src={pictures[27]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(27);
              }}
              className="picture"
              alt="pic"
            />
          </MiddleImgContainer>

          <ListContainer>
            <MiddleLeft>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Fully air-conditioned rooms & suites</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Tea/coffee making facility in all room</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Free Wi-Fi</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Safe deposit box</MiddleText>
              </IconTextContainer>
            </MiddleLeft>
            <MiddleCenter>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Iron & Iron board (on request)</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>24- hour room service</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Flat TV with 90 chaneles</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>IDD telephone</MiddleText>
              </IconTextContainer>
            </MiddleCenter>
            <MiddleRight>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Hot & cold water</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Mini bar & hair dryer in all room</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Smoking & Non- Smoking rooms</MiddleText>
              </IconTextContainer>
              <IconTextContainer>
                <Icon>
                  <BsFillArrowRightCircleFill />
                </Icon>
                <MiddleText>Daily newspaper</MiddleText>
              </IconTextContainer>
            </MiddleRight>
          </ListContainer>
        </Middle>
        <Bottom>
          <BookButton>
            <Link href="https://web.whatsapp.com/send/?phone=+8801894868680">
              BOOK NOW
            </Link>
          </BookButton>
        </Bottom>
      </PremiumSuitsBox>
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button
              onClick={() => setModalOpen(false)}
              className="close-button"
            >
              X
            </button>
            <img
              src={pictures[slideIndex]}
              className="modal-picture"
              alt="picture"
            />
            <ButtonContainer>
              {!hidePreviousArrow && (
                <button
                  onClick={handlePreviousArrow}
                  className="previous-button"
                >
                  {" "}
                  <MdOutlineKeyboardArrowLeft />
                </button>
              )}

              {!hideNextArrow && (
                <button onClick={handleNextArrow} className="next-button">
                  {" "}
                  <MdOutlineKeyboardArrowRight />
                </button>
              )}
            </ButtonContainer>
          </div>
        </div>
      )}
    </Container>
  );
};

export default RoomSuitComponent;
