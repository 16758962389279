import React from "react";
import styled from "styled-components";
import { FaUserTie } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdRoomService } from "react-icons/md";
import { FaBed } from "react-icons/fa";
import { MdRestaurant } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { GiPathDistance } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { responsive } from "../../../Responsive/Responsive";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  overflow: hidden;
  margin: 20px 125px;
  ${responsive({
    margin: "20px 5px",
  })}
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeadingText = styled.h2`
  text-align: center;
  color: rgb(4, 201, 34);
  margin-bottom: 20px;

  ${responsive({
    fontSize: "20px",
  })}
`;
const BoldText = styled.h4`
  text-align: center;
  ${responsive({
    fontSize: "16px",
    margin: "10px 20px",
  })}
`;
const Text = styled.h4`
  text-align: center;
  ${responsive({
    fontSize: "16px",
    margin: "0px 20px",
  })}
`;
const Middle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 30px;
  ${responsive({
    margin: "10px 10px",
  })}
`;

const MiddleTop = styled.div`
  flex: 1;
  display: flex;

  ${responsive({
    flexDirection: "column",
  })}
`;

const MiddleBottom = styled.div`
  flex: 1;
  display: flex;

  ${responsive({
    flexDirection: "column",
  })}
`;

const MiddleLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-spacing: 1px;
  margin: 15px 10px;
  border-radius: 5px;
  background-color: rgb(252, 226, 225);
`;

const CardIcon = styled.span`
  font-size: 30px;
  margin: 5px 0px;
  color: rgb(4, 201, 34);
`;
const CardHeading = styled.h3`
  color: rgb(4, 201, 34);
  ${responsive({
    fontSize: "16px",
  })}
`;
const CardText = styled.span`
  padding: 5px 20px;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;

  ${responsive({
    lineHeight: "25px",
  })}
`;

const MiddleCenter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(252, 226, 225);
  border-radius: 5px;
  margin: 15px 10px;
`;

const MiddleRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgb(252, 226, 225);
  margin: 15px 10px;
`;

const Bottom = styled.div``;

const BottomHeading = styled.h2`
  color: rgb(4, 201, 34);
  text-align: center;

  mar
  ${responsive({
    fontSize: "20px",
  })}
`;

const BottomIcon = styled.div`
  text-align: center;
  font-size: 50px;
  color: rgb(4, 201, 34);
  ${responsive({
    fontSize: "40px",
  })}
`;

const BottomWrapper = styled.div`
  display: flex;

  ${responsive({
    flexDirection: "column",
  })}
`;

const BottomLeft = styled.div`
  flex: 1;
  padding: 50px 20px;
  font-weight: 500;
  background-color: rgb(240, 211, 211);
  margin: 0px 40px;
  word-spacing: 1px;
  border-radius: 10px;
  ${responsive({
    padding: "20px 10px",
    margin: "10px 20px",
  })}
`;

const BottomCenter = styled.div`
  flex: 1;
  padding: 50px 20px;
  font-weight: 500;
  background-color: rgb(240, 211, 211);
  margin: 0px 30px;
  word-spacing: 1px;
  border-radius: 10px;

  ${responsive({
    padding: "20px 10px",
    margin: "10px 20px",
  })}
`;

const BottomRight = styled.div`
  flex: 1;
  padding: 50px 20px;
  font-weight: 500;
  background-color: rgb(240, 211, 211);
  margin: 0px 30px;
  word-spacing: 1px;
  border-radius: 10px;

  ${responsive({
    padding: "20px 10px",
    margin: "10px 20px",
  })}
`;

const InfoText = styled.div`
  margin: 10px 0px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${responsive({
    margin: "10px",
  })}
`;

const Button = styled.button`
  margin: 20px 40px;
  border: none;

  ${responsive({
    margin: "10px 10px",
  })}
`;

const Link = styled(NavLink)`
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;
  background: blue;
  text-decoration: none;

  &:hover {
    color: blue;
    background-color: white;
    border: 1px solid blue;
  }

  ${responsive({
    padding: "5px 10px",
  })}
`;

const BottomLandingComponent = () => {
  AnimationOnScroll();
  return (
    <Container>
      <Top>
        <HeadingText data-aos="fade-up" data-aos-duration="1000">
          WHY CHOOSE US
        </HeadingText>
        <BoldText>
          We are in the heart of the conveniently located in the diplomatic area
          of Banani and Gulshan in Dhaka,
        </BoldText>
        <Text>
          The hotel provides English speaking staff for foreign guests.
        </Text>
      </Top>

      <Middle>
        <MiddleTop>
          <MiddleLeft>
            <CardIcon>
              <FaUserTie />
            </CardIcon>
            <CardHeading>Special offers for corporate clients</CardHeading>
            <CardText>
              The hotel “After Hours Residence” have a system of discounts for
              corporate clients, tourist groups and regular customers, who
              prefer early booking
            </CardText>
          </MiddleLeft>
          <MiddleCenter>
            <CardIcon>
              <MdRestaurant />
            </CardIcon>
            <CardHeading>Restaurant and cafes</CardHeading>
            <CardText>
              Try the delicious cuisine of our restaurant.The Atmosphere of
              chivalry and a variety of dishes will not leave indifferent even
              the most pretentious gourmet.In the close proximity from our hotel
              you can enjoy breakfast, business lunch or romantic dinner. There
              are many restaurants of Thai Chinese, Indian, English, Italian
              cuisine, cozy cafes and pastry shop for business travelers. We
              offer our conference room, equipped with all the necessary
              materials and technology for the success of your business.
            </CardText>
          </MiddleCenter>
          <MiddleRight>
            <CardIcon>
              <MdRoomService />
            </CardIcon>
            <CardHeading>The high-quality service</CardHeading>
            <CardText>
              The hotel “After Hours Residence” provides a high-quality service
              for all guests. We have got well qualified and attentive staffs,
              operative check-in / check-out, taxi service, daily housekeeping
              and change of linen, towels and other services for guests.
            </CardText>
          </MiddleRight>
        </MiddleTop>

        <MiddleBottom>
          <MiddleLeft>
            <CardIcon>
              <FaBed />
            </CardIcon>
            <CardHeading>Comfortable Accommodation</CardHeading>
            <CardText>
              All our rooms combine elements of style, elegant and modern
              facilities that provides you everything for a comfortable stay.
              All rooms have individual air conditioning, bathroom with a
              separate bath or shower (depending on the category of room), free
              Wi-Fi, satellite TV.
            </CardText>
          </MiddleLeft>
          <MiddleCenter>
            <CardIcon>
              <FaMoneyCheckAlt />
            </CardIcon>
            <CardHeading>Our price will surprise you</CardHeading>
            <CardText>
              The hotel “After Hours Residence provides rooms of different
              categories. The wide range of prices can satisfy any guest. Our
              Room rates are very reasonable in terms of prices. The hotel has
              flexible system of discounts.
            </CardText>
          </MiddleCenter>
          <MiddleRight>
            <CardIcon>
              <FaLock />
            </CardIcon>
            <CardHeading>Security</CardHeading>
            <CardText>
              The focused security services are 24 hour security on site, CCTV
              cameras in all public areas, Emergency lights in public areas,
              Emergency flashlights in all rooms, Fire alarms throughout the
              hotel, Fire extinguishers (ABC and CO2), Water hose on all floors,
              Smoke detectors in all room and public area, Water sprinklers in
              all room and public area, Evacuation plan in all rooms, Fire doors
              on all floors, First Aid Box, Designated assembly point for the
              hotel, Armed Guards, Luggage Scanner.
            </CardText>
          </MiddleRight>
        </MiddleBottom>
      </Middle>

      <Bottom>
        <BottomHeading data-aos="fade-up" data-aos-duration="2000">
          DISTANCE
        </BottomHeading>
        <BottomIcon>
          <GiPathDistance />
        </BottomIcon>
        <BottomWrapper>
          <BottomLeft>
            <InfoText>Hazrat Shahjalal International Airport 8.7 km.</InfoText>
            <InfoText>Embassy of the United States 2.2 km.</InfoText>
          </BottomLeft>

          <BottomCenter>
            <InfoText>
              Bangladesh Export Processing Zones Authority 7.5 km.
            </InfoText>
            <InfoText>Hatirjheel 5.6 km.</InfoText>
          </BottomCenter>

          <BottomRight>
            <InfoText>Bashundhara City Shopping Complex 7.1 km.</InfoText>
            <InfoText>Jamuna Future Park 7.8 km.</InfoText>
          </BottomRight>
        </BottomWrapper>
      </Bottom>
      <LinkContainer>
        <Button>
          <Link to="/terms">Terms & Conditions</Link>
        </Button>
        <Button>
          <Link to="/about">Learn More</Link>
        </Button>
      </LinkContainer>
    </Container>
  );
};

export default BottomLandingComponent;
