import React from "react";
import RoomSuitComponent from "../Component/Body/RoomSuits/RoomSuitComponent";

const RoomSuitPage = () => {
  return (
    <div>
      <RoomSuitComponent />
    </div>
  );
};

export default RoomSuitPage;
