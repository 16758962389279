import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Delux from "../../../Assets/Landging_img/delux.jpg";
import PremiumDelux from "../../../Assets/Landging_img/premium delux.jpg";
import PermiumTwin from "../../../Assets/Landging_img/permium twin.jpg";
import PremiumSuites from "../../../Assets/Landging_img/premium suites.jpg";
import { responsive } from "../../../Responsive/Responsive";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  margin: 30px 140px;

  ${responsive({
    margin: "10px",
  })}
`;

const Top = styled.div`
  display: flex;
  margin: 20px 0px;

  ${responsive({
    flexDirection: "column",
  })}
`;

const Heading = styled.h2`
  text-align: center;
  color: rgb(4, 201, 34);
  margin: 20px 0px;
  ${responsive({
    fontSize: "20px",
  })}
`;

const Left = styled.div`
  flex: 1;
  margin: 20px 25px;
  font-size: 16px;
  background-color: rgb(235, 245, 239);
  padding: 20px 50px;
  border-radius: 10px;
  ${responsive({
    margin: "10px",
    padding: "10px",
  })}
`;

const HeadingText = styled.h3`
  font-size: 18px;
  text-align: center;
  color: rgb(4, 201, 34);
  margin: 10px 0px;

  ${responsive({
    fontSize: "18px",
  })}
`;

const ImgContainer = styled.div`
  box-shadow: 3px 3px 3px 3px lightgray;

  ${responsive({
    height: "40vh",
  })}
`;

const Img = styled.img`
  max-width: 100%;
  height: 100%;
  border-radius: 5px;
`;
const TextContainer = styled.div`
  margin-top: 10px;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    lineHeight: "25px",
  })}
`;

const Right = styled.div`
  flex: 1;
  margin: 20px 25px;
  font-size: 16px;
  background-color: rgb(235, 245, 239);
  padding: 20px 50px;
  border-radius: 10px;
  ${responsive({
    margin: "10px",
    padding: "10px",
  })}
`;

const Bottom = styled.div`
  display: flex;

  ${responsive({
    flexDirection: "column",
  })}
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;

  ${responsive({
    margin: "5px",
  })}
`;

const BookButton = styled.button`
  padding: 5px 10px;
  border: none;
  color: white;
  background-color: blue;
  border-radius: 10px;
  cursor: pointer;
  word-spacing: 3px;

  &:hover {
    color: aqua;
    opacity: 0.5;
  }

  ${responsive({
    fontSize: "14px",
  })}
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
`;

const DetailsButton = styled.button`
  border: none;
`;

const Navlink = styled(NavLink)`
  padding: 5px 10px;
  color: white;
  background-color: blue;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  word-spacing: 3px;

  &:hover {
    color: aqua;
    opacity: 0.5;
  }

  ${responsive({
    padding: "5px",
    fontSize: "14px",
  })}
`;

const RoomSuitsBottomComponent = () => {
  AnimationOnScroll();
  return (
    <Container>
      <Heading data-aos="fade-up" data-aos-duration="1000">
        ROOMS & SUITS
      </Heading>
      <Top>
        <Left>
          <ImgContainer>
            <Img src={Delux} />
          </ImgContainer>
          <HeadingText>DELUXE</HeadingText>
          <TextContainer>
            Indulge in the magical charm of our warm welcome, it will give you a
            homely feeling! Deluxe rooms of After Hours Residence Dhaka have
            trendy interiors combining international standard amenities and
            excellent services in a single knot.Averaging 212 square feet. each,
            these well-appointed Deluxe room are...
          </TextContainer>
          <BottomWrapper>
            <BookButton>
              <Link href="https://web.whatsapp.com/send/?phone=+8801894868680">
                BOOK NOW
              </Link>
            </BookButton>
            <DetailsButton>
              <Navlink to="/room">DETAILS</Navlink>
            </DetailsButton>
          </BottomWrapper>
        </Left>
        <Right>
          <ImgContainer>
            <Img src={PremiumDelux} />
          </ImgContainer>
          <HeadingText>PREMIUM DELUXE</HeadingText>
          <TextContainer>
            After Hours Residence Dhaka have trendy interiors combining
            international standard amenities and excellent services in a single
            knot. Averaging 253-280 square feet each, these well-appointed
            Premier deluxe are specially featured with fixed glazed windows,
            rainfall shower and signature bathroom amenities...
          </TextContainer>
          <BottomWrapper>
            <BookButton>
              <Link href="https://web.whatsapp.com/send/?phone=+8801894868680">
                BOOK NOW
              </Link>
            </BookButton>
            <DetailsButton>
              <Navlink to="/room">DETAILS</Navlink>
            </DetailsButton>
          </BottomWrapper>
        </Right>
      </Top>
      <Bottom>
        <Left>
          <ImgContainer>
            <Img src={PermiumTwin} />
          </ImgContainer>
          <HeadingText>PREMIUM TWIN</HeadingText>
          <TextContainer>
            After Hours Residence Dhaka have trendy interiors combining
            international standard amenities and excellent services in a single
            knot. Averaging 216-280 square feet each, these well-appointed
            Premier deluxe are specially featured with fixed glazed windows,
            rainfall shower and signature bathroom amenities...
          </TextContainer>
          <BottomWrapper>
            <BookButton>
              <Link href="https://web.whatsapp.com/send/?phone=+8801894868680">
                BOOK NOW
              </Link>
            </BookButton>
            <DetailsButton>
              <Navlink to="/room">DETAILS</Navlink>
            </DetailsButton>
          </BottomWrapper>
        </Left>
        <Right>
          <ImgContainer>
            <Img src={PremiumSuites} />
          </ImgContainer>
          <HeadingText>PREMIUM SUITES</HeadingText>
          <TextContainer>
            Premium Suite Room is perfect for the personalities that require an
            air of opulence around the environment. Three (3) large suits are
            designed using sophisticated interior and luxurious furnishings so
            that you can feel cozy and homely. Fine quality room amenities are
            merged with amiable room services, exciting dining...
          </TextContainer>
          <BottomWrapper>
            <BookButton>
              <Link href="https://web.whatsapp.com/send/?phone=+8801894868680">
                BOOK NOW
              </Link>
            </BookButton>
            <DetailsButton>
              <Navlink to="/room">DETAILS</Navlink>
            </DetailsButton>
          </BottomWrapper>
        </Right>
      </Bottom>
    </Container>
  );
};

export default RoomSuitsBottomComponent;
