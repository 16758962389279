import styled from "styled-components";
import BottomLandingComponent from "./BottomLandingComponent";
import MiddleLandingComponent from "./MiddleLandingComponent";
import RoomSuitsBottomComponent from "./RoomSuitsBottomComponent";
import TopLandingComponent from "./TopLandingComponent";

const Container = styled.div`
  overflow: hidden;
`;

const MainLandingComponent = () => {
  return (
    <Container>
      <TopLandingComponent />
      <MiddleLandingComponent />
      <BottomLandingComponent />
      <RoomSuitsBottomComponent />
    </Container>
  );
};

export default MainLandingComponent;
