import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

export function AnimationOnScroll() {
  const location = useLocation();
  useEffect(() => {
    AOS.init({
      offset: 120,
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
  }, [location]);
}
