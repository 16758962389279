import React from "react";
import TermsConditionsComponent from "../Component/Body/TermsCondition.sjs/TermsConditionsComponent";

const TermsConditionsPage = () => {
  return (
    <div>
      <TermsConditionsComponent />
    </div>
  );
};

export default TermsConditionsPage;
