import React from "react";
import MeetingEventsComponent from "../Component/Body/MeetingEvent.js/MeetingEventsComponent";

const MeetingEventsPage = () => {
  return (
    <div>
      <MeetingEventsComponent />
    </div>
  );
};

export default MeetingEventsPage;
