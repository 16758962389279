import React from "react";
import styled from "styled-components";
import TermsImage from "../../../Assets/TermsAndCondition/terms-and-conditions.png";
import RefundImage from "../../../Assets/TermsAndCondition/Refund.png";
import PrivacyImage from "../../../Assets/TermsAndCondition/Privacy.png";
import { responsive } from "../../../Responsive/Responsive";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  padding-top: 120px;
`;

const Heading = styled.h1`
  color: rgb(4, 201, 34);
  text-align: center;
  margin: 30px 0px;
  ${responsive({
    fontSize: "22px",
  })}
`;

const Top = styled.div`
  border: 1px solid rgb(4, 201, 34);
  margin: 30px 200px;
  padding: 20px 30px;

  ${responsive({
    margin: "20px",
    padding: "10px",
  })}
`;

const Text = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
  text-align: justify;

  ${responsive({
    margin: "10px",
  })}
`;

const Img = styled.img`
  width: 20px;
  height: 20px;
  margin: 5px;
`;

const Middle = styled.div`
  border: 1px solid rgb(4, 201, 34);
  margin: 30px 200px;
  padding: 20px 30px;

  ${responsive({
    margin: "20px",
    padding: "10px",
  })}
`;

const Bottom = styled.div`
  border: 1px solid rgb(4, 201, 34);
  margin: 30px 200px;
  padding: 20px 30px;

  ${responsive({
    margin: "20px",
    padding: "10px",
  })}
`;
const BoldText = styled.h4`
  text-align: center;
`;
const TermsConditionsComponent = () => {
  AnimationOnScroll();
  return (
    <Container>
      {/* Terms & Conditions Block*/}

      <Heading data-aos="fade-up" data-aos-duration="1000">
        Terms & Conditions
      </Heading>
      <Top>
        <Text>
          <Img src={TermsImage} />
          Our standard Check-In time is at 2pm and check out time is at 12pm.
          There will be 100% full charge for early check in before 8am (subject
          to availability) and 50% additional charge for late checkout until 4pm
          (subject to availability). And 100% full charge for late checkout
          after 6pm (subject to availability). The additional charges will be
          added on the room rent.
        </Text>
        <Text>
          <Img src={TermsImage} />
          One night’s room charge will be applicable for No Show/Cancellation
          unless hotel receives cancellation or other notice via email prior to
          72 hours before the guest arrival date and time by the guest or from
          the concerned organization.
        </Text>
        <Text>
          <Img src={TermsImage} />
          Full reservation charges will be applicable if a confirmed long-term
          reservation is shortened or canceled without giving After Hours
          Residence a written notice prior to 72 hours before the guest’s check
          out date.
        </Text>
        <Text>
          <Img src={TermsImage} />
          Guest agree and undertake to use their room responsibly. Therefore,
          any conduct contrary to accepted standards of behavior and public
          order will result in After Hours Residence asking the guest(s) to
          leave the hotel with no compensation and/or refund if a payment has
          already been made. If no payment has been made, the guest(s) will have
          to pay for the whole reservation before leaving the hotel. The guest
          will be held liable if any criminal activity or suspicious substance
          is found with the guest in After Hours Residence.
        </Text>
        <Text>
          <Img src={TermsImage} />
          During Check-In time, credit card pre-authorization will be taken by
          the Front Office of the respective guest(s). We do not accept personal
          cheque and a signing facility is extended only to those corporate
          accounts that have a credit facility agreement with After Hours
          Residence. Clients having credit arrangement with the hotel must
          communicate the payment instructions in writing at least 24 hours
          prior to guest arrival. We accept Cash in BDT & USD only and also
          accept VISA, MasterCard & American Express as the only electronic mode
          of payment.
        </Text>
        <Text>
          <Img src={TermsImage} />
          Guest(s) are fully responsible for keeping the room in a good shape.
          Guest(s) will be held responsible and will have to pay for the damage
          found in his/her room during checkout.
        </Text>
        <Text>
          <Img src={TermsImage} />
          After Hours reservation confirmation letter does not automatically
          extend credit facility. After Hours Residence reserves all rights to
          make any changes or cancellation without giving any prior notice or
          consent to anyone.
        </Text>
        <Text>
          <Img src={TermsImage} />
          After Hours Residence will be not be held liable (legally) if any
          guest(s) claim to lose their property.
        </Text>
        <Text>
          <Img src={TermsImage} />
          After Hours Residence will not refund any amount for any service(s)
          provided to the guest. Guest(s) understands and automatically accepts
          this term and agreement with After Hours Residence.
        </Text>
        <Text>
          <Img src={TermsImage} />
          AFTER HOURS reserves all rights to make any changes to the Terms &
          Conditions and also reservations without any prior notice.
        </Text>
        <Text>
          <Img src={TermsImage} />
          Corporate(s) & Guest(s) understands and accepts these terms and
          conditions without any alternate conditions.
        </Text>
        <Text>
          <Img src={TermsImage} />
          After Hours Residence will not be held liable in the case of any
          guest(s) getting caught in any criminal activity by the government or
          After Hours authorities. Guest(s) are solely liable if found guilty or
          in possession of illegal substance, items or with an unregistered
          visitor that may be of age or under age.
        </Text>
        <Text>
          <Img src={TermsImage} />
          The Guest (s), visiting and/or staying at the hotel, automatically
          agree to the hotel policies, terms and conditions upon check in. These
          conditions are also automatically applicable on anyone who is
          physically present in the premises of After-Hours Residence.
        </Text>
        <Text>
          <Img src={TermsImage} />
          Meeting Room and Banquet Hall terms and Conditions will be sent upon
          request / requirement
        </Text>
      </Top>

      {/* Refund and Return Policy Block */}

      <Heading data-aos="fade-up" data-aos-duration="1000">
        Refund and Return Policy
      </Heading>
      <Middle>
        <Text>
          <Img src={RefundImage} />
          No refund is applicable on the booking if the booking for 3 or more
          rooms post or after the check-in date is canceled or the reservation
          is shortened, respectively.
        </Text>
        <Text>
          <Img src={RefundImage} />
          After Hours Residence will not refund any amount for any service(s)
          provided to the guest. Guest(s) understands and automatically accepts
          this term and agreement with After Hours Residence.
        </Text>
        <Text>
          <Img src={RefundImage} />
          AFTER HOURS reserves all rights to make any changes to the Refund and
          Return Policy and also reservations without any prior notice.
        </Text>
      </Middle>

      {/* Privacy Policy Block */}
      <Heading data-aos="fade-up" data-aos-duration="1000">
        Privacy Policy
      </Heading>
      <Bottom>
        <BoldText>END-USER LICENSE AGREEMENT</BoldText>
        <Text>
          <Img src={PrivacyImage} />
          IMPORTANT PLEASE READ THE TERMS AND CONDITIONS AND REFUND AND RETURN
          POLICY OF THIS LICENSE AGREEMENT CAREFULLY.
        </Text>
        <Text>
          <Img src={PrivacyImage} />
          Our PRODUCT & SERVICE is protected by our authority.
        </Text>
        <Text>
          <Img src={PrivacyImage} />
          Our all right's reserved under AFTER HOURS RESIDENCE. Any decision and
          any necessary steps are controlled by our higher authority.
        </Text>
      </Bottom>
    </Container>
  );
};

export default TermsConditionsComponent;
