import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";

import Slidder1 from "../../../Assets/Restaurant_Page_img/1.jpg";
import Slidder2 from "../../../Assets/Restaurant_Page_img/2.jpg";
import Slidder3 from "../../../Assets/Restaurant_Page_img/3.jpg";
import Slidder4 from "../../../Assets/Restaurant_Page_img/4.jpg";
import Slidder5 from "../../../Assets/Restaurant_Page_img/5.jpg";
import Slidder6 from "../../../Assets/Restaurant_Page_img/6.jpg";
import Slidder7 from "../../../Assets/Restaurant_Page_img/7.jpg";
import Slidder8 from "../../../Assets/Restaurant_Page_img/8.jpg";
import Slidder9 from "../../../Assets/Restaurant_Page_img/9.jpg";
import Slidder10 from "../../../Assets/Restaurant_Page_img/10.jpg";
import Slidder11 from "../../../Assets/Restaurant_Page_img/11.jpg";
import Slidder12 from "../../../Assets/Restaurant_Page_img/12.jpg";
import Slidder13 from "../../../Assets/Restaurant_Page_img/13.jpg";
import Slidder14 from "../../../Assets/Restaurant_Page_img/14.jpg";
import Slidder15 from "../../../Assets/Restaurant_Page_img/15.jpg";

import { responsive } from "../../../Responsive/Responsive";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  margin: 10px;
  ${responsive({
    margin: "25px",
  })}
`;

const Heading = styled.h2`
  color: rgb(4, 201, 34);
  text-align: center;
  margin: 20px 0px;
`;

const ImgSlidder = styled.div`
  border: 1px solid green;
  padding: 10px 40px;
`;

const ImgContainer = styled.div`
  padding: 5px;
  border-radius: 10px;
`;

const Img = styled.img``;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  AnimationOnScroll();
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

export default class AutoPlay extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000,
      rtl: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <Container>
        <Heading data-aos="fade-up" data-aos-duration="2000">
          FOOD
        </Heading>
        <ImgSlidder>
          <Slider {...settings}>
            <ImgContainer>
              <Img src={Slidder1} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder2} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder3} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder4} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder5} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder6} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder7} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder8} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder9} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder10} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder11} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder12} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder13} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder14} />
            </ImgContainer>
            <ImgContainer>
              <Img src={Slidder15} />
            </ImgContainer>
          </Slider>
        </ImgSlidder>
      </Container>
    );
  }
}
