import styled from "styled-components";
import TopImg from "../../../Assets/About_Page_img/TopImg.jpg";
import MiddleImg1 from "../../../Assets/About_Page_img/MiddleImg1.jpg";
import MiddleImg2 from "../../../Assets/About_Page_img/MiddleImg2.jpg";
import { Link } from "react-router-dom";
import { responsive } from "../../../Responsive/Responsive";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  overflow: hidden;
`;

const Top = styled.div`
  background-size: cover;
  width: 100vw;
  height: 100vh;
  ${responsive({
    height: "50vh",
  })}
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const Middle = styled.div`
  margin: 20px 160px;
  ${responsive({
    margin: "10px",
  })}}
`;

const MiddleHeadingText = styled.h1`
  color: rgb(4, 201, 34);
  text-align: center;

  ${responsive({
    fontSize: "22px",
  })}
`;
const Text = styled.div`
  margin: 20px 5px;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    margin: "10px 15px",
    lineHeight: "25px",
  })}
`;

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding: 20px 200px;

  ${responsive({
    flexDirection: "column",
    padding: "0px",
  })}
`;

const ImgContainer = styled.div`
  flex: 1;
  margin: 20px;
  box-shadow: 2px 2px 2px 2px lightgray;

  ${responsive({
    margin: "10px 15px",
    height: "40vh",
  })}
`;
const ImageOne = styled.img`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const MiddleTextRight = styled.div`
  flex: 1;
  margin: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;

  ${responsive({
    margin: "10px 15px",
    lineHeight: "25px",
  })}
`;

const MiddleTextLeft = styled.div`
  flex: 1;
  margin: 30px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  word-spacing: 1px;
  text-align: justify;
  ${responsive({
    margin: "10px 15px",
    lineHeight: "25px",
  })}
`;

const ImageTwo = styled.img`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  ${responsive({
    margin: "0px",
  })}
`;

const Bottom = styled.div``;

const BottomHeading = styled.h2`
  margin: 20px 40px;
  text-align: center;

  ${responsive({
    margin: "10px 20px",
    fontSize: "20px",
  })}
`;

const BottomWrapper = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex: 1;
  margin: 12px;

  ${responsive({
    margin: "10px",
  })}
`;
const Right = styled.div`
  flex: 1;
  margin: 12px;

  ${responsive({
    margin: "10px",
  })}
`;

const FooterLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(4, 201, 34);
  font-weight: 550;
  font-size: 14px;
  padding: 5px 0px;

  &:hover,
  &:focus {
    color: rgb(252, 3, 3);
  }
  ${responsive({
    margin: "10px 10px",
    fontSize: "12px",
  })}
`;

const AboutComponent = () => {
  AnimationOnScroll();
  return (
    <Container>
      <Top>
        <Img loading="lazy" src={TopImg} />
      </Top>

      <Middle>
        <MiddleHeadingText data-aos="fade-up" data-aos-duration="2000">
          ABOUT US
        </MiddleHeadingText>
        <Text>
          Welcome to, After Hours Residence, a new 4-star hotel is conveniently
          located in the diplomatic area of Banani and Gulshan in Dhaka,
          Bangladesh, on the edge of beautiful Gulshan lake and the next to the
          American Club. It is only 10 minutes’ drive from one of the best
          shopping areas in Dhaka and 20 minutes’ drive from the Hazrat
          Shahjalal International Airport, Dhaka.
        </Text>

        <Wrapper>
          <ImgContainer>
            <ImageOne loading="lazy" src={MiddleImg1} />
          </ImgContainer>
          <MiddleTextRight>
            As our valued guest we are committed to creating an environment of
            security and wellbeing throughout your stay. To ensure safety of our
            guests, the entire hotel is equipped with modern firefighting
            system. There are round the clock security guards and CCTV
            monitoring also.
          </MiddleTextRight>
        </Wrapper>
        <Text>
          The hotel features distinctive western architecture with 46
          well-appointed rooms and suite. Our all-day-dining room offers an
          interactive buffet-style concept and a creative chart menu.
        </Text>
        <Wrapper>
          <MiddleTextLeft>
            With a multitude of indoor and outdoor event venues offering
            breathtaking views across area. you will be spoilt for choice in
            selecting your dream venue. Whether it is the aristocratic rooms and
            bars of After Hours where private gathering are held the event
            venues at AFTER HOURS never fail to charm.
          </MiddleTextLeft>
          <ImgContainer>
            <ImageTwo loading="lazy" src={MiddleImg2} />
          </ImgContainer>
        </Wrapper>
      </Middle>

      <Bottom>
        <BottomHeading data-aos="fade-up" data-aos-duration="2000">
          The After Hours Residence provides you a wide range of facilities.
        </BottomHeading>
        <BottomWrapper>
          <Left>
            <FooterLink to="/room#delux">Deluxe</FooterLink>
            <FooterLink to="/room#premiumdelux">Premium Deluxe</FooterLink>
            <FooterLink to="/room">Premium Twin</FooterLink>
            <FooterLink to="/room">Premium Suits</FooterLink>
          </Left>
          <Right>
            <FooterLink to="/meeting">Meeting & Events</FooterLink>
            <FooterLink to="/restaurant">Restaurents</FooterLink>
            <FooterLink to="/wellness">Wellness</FooterLink>
            <FooterLink to="/meeting">Conference Hall</FooterLink>
          </Right>
        </BottomWrapper>
      </Bottom>
    </Container>
  );
};

export default AboutComponent;
