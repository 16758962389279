import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { responsive } from "../Responsive/Responsive";

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TopHeading = styled.h1`
  font-size: 100px;
  color: blue;
  margin: 30px 0px;
  ${responsive({
    fontSize: "50px",
  })}
`;

const Text = styled.span`
  font-size: 20px;
  font-weight: 600;
  ${responsive({
    fontSize: "18px",
  })}
`;

const Button = styled.button`
  margin: 20px 0px;
  border: none;
`;

const Link = styled(NavLink)`
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 600;
  background: blue;
  text-decoration: none;

  &:hover {
    color: blue;
    background-color: white;
    border: 1px solid blue;
  }

  ${responsive({
    fontSize: "12px",
  })}
`;
const ErrorPage = () => {
  return (
    <Container>
      <TopHeading>Ooops!</TopHeading>
      <Text> 404 - PAGE NOT FOUND</Text>
      <Button>
        <Link to="/">GO TO HOMEPAGE</Link>
      </Button>
    </Container>
  );
};

export default ErrorPage;
