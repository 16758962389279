import React from "react";
import RestaurantsComponent from "../Component/Body/Restaurants/RestaurantsComponent";

const RestaurantPage = () => {
  return (
    <div>
      <RestaurantsComponent />
    </div>
  );
};

export default RestaurantPage;
