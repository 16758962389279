import styled from "styled-components";
import TopImg from "../../../Assets/Wellness_Page_img/TopImg.jpg";
import Img1 from "../../../Assets/Wellness_Page_img/Img1.jpg";
import Img2 from "../../../Assets/Wellness_Page_img/Img2.jpg";
import Img3 from "../../../Assets/Wellness_Page_img/Img3.jpg";
import Img4 from "../../../Assets/Wellness_Page_img/Img4.jpg";
import { responsive } from "../../../Responsive/Responsive";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  overflow: hidden;
`;

const Top = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  ${responsive({
    height: "50vh",
  })}
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const Middle = styled.div`
  margin: 20px 150px;

  ${responsive({
    margin: "15px",
  })}
`;

const MiddleHeadingText = styled.h1`
  color: rgb(4, 201, 34);
  text-align: center;
  ${responsive({
    margin: "10px 15px",
    fontSize: "22px",
  })}
`;
const Text = styled.div`
  margin: 20px;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    LineHeight: "25px",
    margin: "10px",
  })}
`;

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding: 20px 80px;

  ${responsive({
    flexDirection: "column",
    padding: "0px",
  })}
`;

const ImgContainer = styled.div`
  flex: 1;
  margin: 20px;
  box-shadow: 2px 2px 2px 2px lightgray;

  ${responsive({
    margin: "10px",
    // height: "300px",
  })}
`;
const ImageOne = styled.img`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const MiddleTextRight = styled.div`
  flex: 1;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    lineHeight: "25px",
  })}
`;

const MiddleTextLeft = styled.div`
  flex: 1;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    lineHeight: "25px",
    margin: "10px",
  })}
`;

const ImageTwo = styled.img`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const Bottom = styled.div`
  margin: 20px 150px;

  ${responsive({
    margin: "10px",
  })}
`;

const BottomHeadingText = styled.h1`
  color: rgb(4, 201, 34);
  text-align: center;
  ${responsive({
    fontSize: "22px",
  })}
`;

const BottomTextRight = styled.div`
  flex: 1;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    lineHeight: "25px",
    margin: "10px",
  })}
`;

const BottomTextLeft = styled.div`
  flex: 1;
  margin: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    lineHeight: "25px",
    margin: "10px",
  })}
`;

const WelnessComponent = () => {
  AnimationOnScroll();
  return (
    <Container>
      <Top>
        <Img src={TopImg} />
      </Top>

      <Middle>
        <MiddleHeadingText data-aos="fade-up" data-aos-duration="2000">
          WELLNESS
        </MiddleHeadingText>
        <Text>
          Discover tranquility for the body and mind at one of the city’s best
          spas, Spa Esc. A range of soothing holistic treatments including
          facial therapies, aromatherapy and reflexology helps guests achieve
          pure rejuvenation. Our specially designed treatment rooms provide a
          serene escape, where time slips by and stress dissolves away.
        </Text>

        <Wrapper>
          <ImgContainer>
            <ImageOne loading="lazy" src={Img1} />
          </ImgContainer>
          <MiddleTextRight>
            Experience the therapeutic benefits of water at After Hour Spa,
            where remineralizing experiences recreate the healing properties of
            earth's mineral-rich waters. Each one of our unique spa services
            nourishes and heals your body, in part by helping replace trace
            minerals. Demand for After Hour Spa services is high, especially on
            weekends. We highly recommend appointments be made several weeks in
            advance. 25-minute spa services are not available on Saturdays and
            Sundays year round.
          </MiddleTextRight>
        </Wrapper>
        <Text>
          As a thank you to our frequent guests, we’re excited to launch our new
          Spa Loyalty Program in After Hours. We will keep track of everything
          for you. Simply sign up during your next spa visit to begin earning
          free spa services and retail products. Some restrictions apply.We as
          pioneers in the Tortuguero area provide innovative and relaxing
          techniques for your body, spirit and soul.
        </Text>
        <Wrapper>
          <MiddleTextLeft>
            We offer spa packages for different types of needs not only
            individual We as pioneers in the area provide innovative and
            relaxing techniques for your body, spirit and soul. We offer spa
            packages for different types of needs not only individually, but
            also as couples. It includes exfoliation a wrap with the product
            appropriate given your skin s need ,followed by a warm shower then
            the procedure will be finished with a relaxing 40 minute massage.
          </MiddleTextLeft>
          <ImgContainer>
            <ImageTwo loading="lazy" src={Img2} />
          </ImgContainer>
        </Wrapper>
        <Text>
          Spa presents an extensive palette of global wellness and beauty
          rituals, honoring culture from every corner of the earth coming
          together to offer the ultimate spa experience. After Hour spa artisans
          will perform gentle and replenishing therapies painstakingly designed
          to suit guest’s needs.
        </Text>
      </Middle>

      <Bottom>
        <BottomHeadingText data-aos="fade-up" data-aos-duration="2000">
          GYM & FITNESS
        </BottomHeadingText>

        <Wrapper>
          <ImgContainer>
            <ImageOne loading="lazy" src={Img3} />
          </ImgContainer>
          <BottomTextRight>
            Our fitness studio, offering several disciplines to tackle any
            health and fitness goal. We will provide you with every platform and
            tool you will need to achieve your best and most healthy self. From
            group fitness classes, personal training, and nutritional guidance
            or meal prep planning, Our team of qualified professionals will
            meticulously design plans, outline goals and take the steps needed
            to ensure the success of every one health and fitness goals.
          </BottomTextRight>
        </Wrapper>
        <Wrapper>
          <BottomTextLeft>
            The Our fitness center located on roof top of After Hours Residence.
            We provide different types of training, you can come to us from the
            feet for building strength, good to get back into shape or just some
            lose pounds! Take advantage of our spacious cardio room or get
            started with our many power equipment. We have approximately 210
            devices, just what numbers: 17 treadmills, cross trainers, bikes, 18
            15 4 2 benchpress banks, smith machines, 10 circuit machines, 100
            power equipment including plate loaded equipment and many other
            appliances!
          </BottomTextLeft>
          <ImgContainer>
            <ImageTwo loading="lazy" src={Img4} />
          </ImgContainer>
        </Wrapper>
      </Bottom>
    </Container>
  );
};

export default WelnessComponent;
