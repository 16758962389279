import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TopImg from "../../../Assets/Restaurant_Page_img/TopImg.jpg";
import Img1 from "../../../Assets/Restaurant_Page_img/Img1.jpg";
import Img2 from "../../../Assets/Restaurant_Page_img/Img2.jpg";
import GalleryImg1 from "../../../Assets/Restaurant_Page_img/GalleryImg1.jpg";
import GalleryImg2 from "../../../Assets/Restaurant_Page_img/GalleryImg2.jpg";
import GalleryImg3 from "../../../Assets/Restaurant_Page_img/GalleryImg3.jpg";
import GalleryImg4 from "../../../Assets/Restaurant_Page_img/GalleryImg4.jpg";
import GalleryImg5 from "../../../Assets/Restaurant_Page_img/GalleryImg5.jpg";
import GalleryImg6 from "../../../Assets/Restaurant_Page_img/GalleryImg6.jpg";
import GalleryImg7 from "../../../Assets/Restaurant_Page_img/GalleryImg7.jpg";
import GalleryImg8 from "../../../Assets/Restaurant_Page_img/GalleryImg8.jpg";
import GalleryImg9 from "../../../Assets/Restaurant_Page_img/GalleryImg9.jpg";
import GalleryImg10 from "../../../Assets/Restaurant_Page_img/GalleryImg10.jpg";
import GalleryImg11 from "../../../Assets/Restaurant_Page_img/GalleryImg11.jpg";
import GalleryImg12 from "../../../Assets/Restaurant_Page_img/GalleryImg12.jpg";
import GalleryImg13 from "../../../Assets/Restaurant_Page_img/GalleryImg13.jpg";
import GalleryImg14 from "../../../Assets/Restaurant_Page_img/GalleryImg14.jpg";
import GalleryImg15 from "../../../Assets/Restaurant_Page_img/GalleryImg15.jpg";
import GalleryImg17 from "../../../Assets/Restaurant_Page_img/GalleryImg17.jpg";
import GalleryImg16 from "../../../Assets/Restaurant_Page_img/GalleryImg16.jpg";
import GalleryImg18 from "../../../Assets/Restaurant_Page_img/GalleryImg18.jpg";
import GalleryImg19 from "../../../Assets/Restaurant_Page_img/GalleryImg19.jpg";
import GalleryImg20 from "../../../Assets/Restaurant_Page_img/GalleryImg20.jpg";
import GalleryImg21 from "../../../Assets/Restaurant_Page_img/GalleryImg22.jpg";
import GalleryImg22 from "../../../Assets/Restaurant_Page_img/GalleryImg10.jpg";
import GalleryImg23 from "../../../Assets/Restaurant_Page_img/GalleryImg23.jpg";
import GalleryImg24 from "../../../Assets/Restaurant_Page_img/GalleryImg24.jpg";
import AutoPlay from "./FoodSlidder";
import { responsive } from "../../../Responsive/Responsive";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  overflow: hidden;
`;

const Top = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  ${responsive({
    height: "50vh",
  })}
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const Bottom = styled.div`
  margin: 40px 150px;
  ${responsive({
    margin: "20px 0px",
  })}
`;

const BottomHeadingText = styled.h1`
  color: rgb(4, 201, 34);
  text-align: center;
  margin: 50px;
  ${responsive({
    margin: "10px 15px",
    fontSize: "22px",
  })}
`;

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  margin: 20px 120px;

  ${responsive({
    margin: "20px 20px",
    flexDirection: "column",
  })}
`;

const ImgContainer = styled.div`
  flex: 1;
  margin: 20px 15px;
  box-shadow: 2px 2px 2px 2px lightgray;
  ${responsive({
    margin: "5px",
    height: "50vh",
  })}
`;
const ImageOne = styled.img`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomTextRight = styled.div`
  flex: 1;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-spacing: 1px;
  line-height: 30px;
  text-align: justify;
  ${responsive({
    lineHeight: "25px",
    margin: "20px 5px",
  })}
`;

const WrapperBottopm = styled.div`
  display: flex;
  overflow: hidden;
  margin: 20px 120px;

  ${responsive({
    flexDirection: "column",
    margin: "20px",
  })}
`;

const BottomWrapperTextLeft = styled.div`
  flex: 1;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  line-height: 30px;
  word-spacing: 1px;
  ${responsive({
    lineHeight: "25px",
    margin: "20px 5px",
  })}
`;

const BottomImgContainer = styled.div`
  flex: 1;
  margin: 20px 0px;
  box-shadow: 2px 2px 2px 2px lightgray;

  ${responsive({
    margin: "5px",
    height: "50vh",
  })}
`;

const ImageTwo = styled.img`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GalleryText = styled.h2`
  text-align: center;
  margin: 30px 0px;
  color: rgb(4, 201, 34);
  ${responsive({
    margin: "10px 15px",
    fontSize: "20px",
  })}
`;

const ImgGallery = styled.div`
  overflow: hidden;
  border: 1px solid rgb(4, 201, 34);
  margin: 30px 20px;

  ${responsive({
    margin: "20px 25px",
  })}
`;

const GalleryTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${responsive({
    flexDirection: "column",
    height: "100%",
    width: "100%",
  })}
`;

const GalleryBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${responsive({
    height: "100%",
    width: "100%",
    flexDirection: "column",
  })}
`;

const GalleryImg = styled.img`
  width: 12%;
  height: 10%;
  margin: 5px;

  ${responsive({
    width: "90%",
    height: "50%",
  })}
`;

const Text = styled.div`
  margin: 20px 10px;
  line-height: 30px;
  word-spacing: 1px;
  text-align: justify;

  ${responsive({
    margin: "10px 20px",
    // fontSize: "14px",
  })}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RestaurantsComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [hidePreviousArrow, setHidePreviousArrow] = useState(true);
  const [hideNextArrow, setHideNextArrow] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const pictures = [
    GalleryImg1,
    GalleryImg2,
    GalleryImg3,
    GalleryImg4,
    GalleryImg5,
    GalleryImg6,
    GalleryImg7,
    GalleryImg8,
    GalleryImg9,
    GalleryImg10,
    GalleryImg11,
    GalleryImg12,
    GalleryImg13,
    GalleryImg14,
    GalleryImg15,
    GalleryImg16,
    GalleryImg17,
    GalleryImg18,
    GalleryImg19,
    GalleryImg20,
    GalleryImg21,
    GalleryImg22,
    GalleryImg23,
    GalleryImg24,
  ];

  useEffect(() => {
    if (slideIndex === 0) {
      setHidePreviousArrow(true);
    } else {
      setHidePreviousArrow(false);
    }
    if (slideIndex === pictures.length - 1) {
      setHideNextArrow(true);
    } else {
      setHideNextArrow(false);
    }
  }, [slideIndex, pictures.length]);

  function handlePreviousArrow() {
    setSlideIndex(slideIndex - 1);
  }

  function handleNextArrow() {
    setSlideIndex(slideIndex + 1);
  }

  AnimationOnScroll();
  return (
    <Container>
      <Top>
        <Img src={TopImg} />
      </Top>

      <Bottom>
        <BottomHeadingText data-aos="fade-up" data-aos-duration="2000">
          ROOFTOP RESTAURANT
        </BottomHeadingText>
        <Wrapper>
          <ImgContainer>
            <ImageOne src={Img1} />
          </ImgContainer>
          <BottomTextRight>
            A restaurant under one roof where you can have Breakfast Lunch and
            Dinner. The restaurant will provide a large chart and buffet service
            including Chinese Thai Italian Indian food. The imagination and
            talent of our international chefs is reflected in the excellent
            cuisine that can be savored in the Restaurant.
          </BottomTextRight>
        </Wrapper>

        <GalleryText data-aos="fade-up" data-aos-duration="2000">
          GALLERY
        </GalleryText>
        <ImgGallery className="App">
          <GalleryTop>
            <GalleryImg
              src={pictures[0]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(0);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[1]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(1);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[2]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(2);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[3]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(3);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[4]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(4);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[5]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(5);
              }}
              className="picture"
              alt="pic"
            />
          </GalleryTop>

          <GalleryBottom>
            <GalleryImg
              src={pictures[6]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(6);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[7]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(7);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[8]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(8);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[9]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(9);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[10]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(10);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[11]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(11);
              }}
              className="picture"
              alt="pic"
            />
          </GalleryBottom>
        </ImgGallery>

        <BottomHeadingText data-aos="fade-up" data-aos-duration="1000">
          GROUND FLOOR RESTAURANT
        </BottomHeadingText>

        <WrapperBottopm>
          <BottomWrapperTextLeft>
            Located in the Lobby area our special coffee shop that offers 24
            hours services will surely be a comfortable place for you to enjoy a
            great cup of coffee either you prefer gourmet espresso and drip
            smoothies with a delicious sand. You can have just a glass of fresh
            juice of your choice.
          </BottomWrapperTextLeft>
          <BottomImgContainer>
            <ImageTwo src={Img2} />
          </BottomImgContainer>
        </WrapperBottopm>
      </Bottom>

      <Bottom>
        <GalleryText data-aos="fade-up" data-aos-duration="2000">
          GALLERY
        </GalleryText>

        <ImgGallery>
          <GalleryTop>
            <GalleryImg
              src={pictures[12]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(12);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[13]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(13);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[14]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(14);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[15]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(15);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[16]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(16);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[17]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(17);
              }}
              className="picture"
              alt="pic"
            />
          </GalleryTop>

          <GalleryBottom>
            <GalleryImg
              src={pictures[18]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(18);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[19]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(19);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[20]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(20);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[21]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(21);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[22]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(22);
              }}
              className="picture"
              alt="pic"
            />
            <GalleryImg
              src={pictures[23]}
              onClick={() => {
                setModalOpen(true);
                setSlideIndex(23);
              }}
              className="picture"
              alt="pic"
            />
          </GalleryBottom>
        </ImgGallery>

        <Text>
          Our pastry offerings may vary with seasonality but the primary line
          will be muffins, breads, cookies, scones, and rolls. So, either to
          meet friends or to just relax, After Hours is the finger licking place
          within your hope and reach.
        </Text>
        <AutoPlay />
      </Bottom>

      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button
              className="close-button"
              onClick={() => setModalOpen(false)}
            >
              X
            </button>
            <img src={pictures[slideIndex]} className="modal-picture" />
            <ButtonContainer>
              {!hidePreviousArrow && (
                <button
                  onClick={handlePreviousArrow}
                  className="previous-button"
                >
                  <MdOutlineKeyboardArrowLeft />
                </button>
              )}

              {!hideNextArrow && (
                <button onClick={handleNextArrow} className="next-button">
                  {" "}
                  <MdOutlineKeyboardArrowRight />
                </button>
              )}
            </ButtonContainer>
          </div>
        </div>
      )}
    </Container>
  );
};

export default RestaurantsComponent;
