import React from "react";
import FacilitiesComponent from "../Component/Body/Facilities/FacilitiesComponent";

const FacilitiesPage = () => {
  return (
    <div>
      <FacilitiesComponent />
    </div>
  );
};

export default FacilitiesPage;
