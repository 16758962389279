import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Component/Header/NavbarComponent";
import FooterComponent from "./Component/Footer/FooterComponent";
import LandingPage from "./Pages/LandingPage";
import AboutPage from "./Pages/AboutPage";
import RoomSuitPage from "./Pages/RoomSuitPage";
import FacilitiesPage from "./Pages/FacilitiesPage";
import WellnessPage from "./Pages/WellnessPage";
import RestaurantPage from "./Pages/RestaurantPage";
import MeetingEventsPage from "./Pages/MeetingEventsPage";
import ErrorPage from "./Pages/ErrorPage";
import ScrollToTop from "./Component/ExtraFeature/ScrollToTop";
import TermsConditionsPage from "./Pages/TermsConditionsPage";
// import SideNav from "./Component/ExtraFeature/SideNav";

function App() {
  return (
    <div className="App">
      <Navbar />
      {/* <SideNav /> */}
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/room" element={<RoomSuitPage />} />
        <Route path="/facilities" element={<FacilitiesPage />} />
        <Route path="/wellness" element={<WellnessPage />} />
        <Route path="/restaurant" element={<RestaurantPage />} />
        <Route path="/meeting" element={<MeetingEventsPage />} />
        <Route path="/terms" element={<TermsConditionsPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <section id="footer">
        <FooterComponent />
      </section>
    </div>
  );
}
export default App;
