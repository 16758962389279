import React from "react";
import AboutComponent from "../Component/Body/About/AboutComponent";

const AboutPage = () => {
  return (
    <div>
      <AboutComponent />
    </div>
  );
};

export default AboutPage;
