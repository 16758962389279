import React from "react";
import WellnessComponent from "../Component/Body/Wellness/WellnessComponent";

const WellnessPage = () => {
  return (
    <div>
      <WellnessComponent />
    </div>
  );
};

export default WellnessPage;
