import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { responsive } from "../../Responsive/Responsive";
// import { AiOutlineCopyright } from "react-icons/ai";
import { AnimationOnScroll } from "../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  background-color: #4f9543;
  overflow: hidden;
`;

const Top = styled.div`
  display: flex;
  margin: 0px 140px;
  color: white;
  ${responsive({
    flexDirection: "column",
    margin: "0px 15px",
  })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: black !important;
  margin: 50px 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

  ${responsive({
    margin: "10px",
  })}
`;

const LeftContainerText = styled.p`
  /* margin: 5px; */
`;

const Heading = styled.span`
  font-weight: 500;
  margin: 15px 20px;
  padding: 20px 50px;
  background-color: #4f9543;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: 550;

  ${responsive({
    margin: "10px",
    padding: "10px",
    fontSize: "14px",
  })}
`;

const AddressContainer = styled.div`
  padding: 2px 0px;
  margin-left: 70px;
  font-size: 14px;
  margin-bottom: 10px;
  ${responsive({
    marginLeft: "20px",
    fontSize: "14px",
  })}
`;

const PhoneNumberContainer = styled.div`
  font-weight: 400;
  margin-bottom: 20px;
`;
const PhoneText = styled.p`
  font-weight: 400;
  padding: 2px 0px;
  /* margin: 0px; */
  /* font-size: 14px; */

  ${responsive({
    fontSize: "14px",
  })}
`;

const PhoneNumber = styled.div``;

const EmailText = styled.p`
  font-weight: 400;
  padding: 2px 0px;
  /* margin: 5px; */
  /* font-size: 14px; */

  ${responsive({
    fontSize: "14px",
  })}
`;

const Center = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: black !important;
  margin: 50px 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

  ${responsive({
    margin: "10px",
  })}
`;

const UnOrderList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 70px;
  ${responsive({
    marginLeft: "10px",
  })}
`;

const List = styled.li`
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  margin: 10px;
`;

const Link = styled(NavLink)`
  color: white;
  text-decoration: none;

  &:hover {
    color: blue;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: black !important;
  margin: 50px 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

  ${responsive({
    margin: "10px",
  })}
`;

const RightTextContainer = styled.div`
  padding: 15px 20px;
  text-align: justify;
  line-height: 20px;
  word-spacing: 3px;
  font-size: 14px;

  ${responsive({
    margin: "10px",
  })}
`;
const RightWrapper = styled.div``;

const ClickableEmail = styled.a`
  text-decoration: none;
  color: white;

  &:hover {
    color: blue;
  }
`;

const ClickableNumber = styled.a`
  text-decoration: none;
  color: white;
`;

// const Bottom = styled.div``;

// const DevTeam = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: white;
//   font-size: 10px;
//   background-color: black;
//   padding: 10px 5px;

//   ${responsive({
//     fontSize: "7px",
//   })}

//   @media screen and (max-width: 375px) {
//     font-size: 6px;
//   }
// `;
// const Specialtext = styled.span`
//   color: rgb(245, 17, 5);
//   margin-left: 5px;
// `;

const FooterComponent = () => {
  AnimationOnScroll();
  return (
    <Container>
      <Top>
        <Left>
          <Heading data-aos="fade-up" data-aos-duration="2000">
            CONTACT
          </Heading>

          <AddressContainer>
            <span style={{ fontSize: "13px" }}>ADDRESS:</span>
            <LeftContainerText>Road: 18, Block A</LeftContainerText>
            <LeftContainerText style={{ marginTop: "2px" }}>
              House: 12, Banani
            </LeftContainerText>
            <LeftContainerText
              style={{ marginTop: "2px", marginBottom: "5px" }}
            >
              Dhaka-1213, Bangladesh
            </LeftContainerText>
            <PhoneNumberContainer>
              <PhoneText>
                <span style={{ fontSize: "13px" }}>SALES:</span>{" "}
                <div style={{ marginTop: "2px" }}>
                  <ClickableNumber href="tel:+8801873-435356">
                    +8801873-435356
                  </ClickableNumber>
                </div>
                <PhoneNumber>
                  <ClickableNumber href="tel:+8801817-691179">
                    +8801817-691179
                  </ClickableNumber>
                </PhoneNumber>
              </PhoneText>

              <PhoneText>
                <div style={{ fontSize: "13px" }}>RESERVATION:</div>
                <PhoneNumber>
                  <ClickableNumber href="tel:+8801894-868680">
                    +8801894-868680
                  </ClickableNumber>
                </PhoneNumber>
                <PhoneNumber style={{ marginTop: "2px" }}>
                  <ClickableNumber href="tel: +8801894-868681">
                    +8801894-868681
                  </ClickableNumber>
                </PhoneNumber>
              </PhoneText>

              <EmailText>
                <span style={{ fontSize: "13px" }}>EMAIL: </span>
                <div>
                  <ClickableEmail href="mailto:info@afterhoursbd.com">
                    info@afterhoursbd.com
                  </ClickableEmail>
                </div>
                <div style={{ marginTop: "2px" }}>
                  <ClickableEmail href="mailto:info@afterhoursbd.com">
                    sales@afterhoursbd.com
                  </ClickableEmail>
                </div>
              </EmailText>
            </PhoneNumberContainer>
          </AddressContainer>
        </Left>

        <Center>
          <Heading data-aos="fade-up" data-aos-duration="2000">
            USEFULL LINK
          </Heading>
          <UnOrderList>
            <List>
              <Link to="/">HOME</Link>
            </List>
            <List>
              <Link to="/about">ABOUT</Link>
            </List>
            <List>
              <Link to="/room">ROOM & SUITS</Link>
            </List>
            <List>
              <Link to="/facilities">FACILITIES</Link>
            </List>
            <List>
              <Link to="/wellness">WELLNESS</Link>
            </List>
            <List>
              <Link to="/restaurant">RESTAURANTS</Link>
            </List>
            <List>
              <Link to="/meeting">MEETING & EVENTS</Link>
            </List>
            <List>
              <Link to="/terms">TERM'S & CONDITION'S</Link>
            </List>
          </UnOrderList>
        </Center>
        <Right>
          <Heading data-aos="fade-up" data-aos-duration="2000">
            GET IN TOUCH
          </Heading>
          <RightWrapper>
            <RightTextContainer>
              “We can provide quick and efficient service.If you want to know
              anything related to our service and offers please don't hesitate
              to contact with us.”
            </RightTextContainer>
          </RightWrapper>
        </Right>
      </Top>

      {/* <Bottom>
        <DevTeam>
          Copyrights
          <Icon>
            <AiOutlineCopyright />
          </Icon>
          2023. All rights reserved by <Specialtext>Innovative IT</Specialtext>.
          Design & Developed By <Specialtext>Innovative IT Team.</Specialtext>
        </DevTeam>
      </Bottom> */}
      <ScrollToTop smooth top="1000" />
    </Container>
  );
};

export default FooterComponent;
