import React from "react";
import styled from "styled-components";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import TopImg from "../../../Assets/Facilities/TopImg.jpg";
import { responsive } from "../../../Responsive/Responsive";
import { AnimationOnScroll } from "../../ExtraFeature/AnimationOnScroll";

const Container = styled.div`
  overflow: hidden;
`;

const Top = styled.div`
  margin: 20px 0px;
  overflow: hidden;
  height: 100vh;
  ${responsive({
    height: "50vh",
  })}
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const Bottom = styled.div``;

const Heading = styled.h1`
  text-align: center;
  color: rgb(4, 201, 34);
  ${responsive({
    margin: "10px 15px",
    fontSize: "22px",
  })}
`;

const Wrapper = styled.div`
  display: flex;
  margin: 20px 150px;

  ${responsive({
    flexDirection: "column",
    margin: "10px 20px",
  })}
`;
const Left = styled.div`
  flex: 1;
  background-color: rgb(237, 228, 228);
  border-radius: 10px;
  padding: 10px 0px;
  margin: 20px 15px;

  ${responsive({
    margin: "5px",
  })}
`;

const HeadingText = styled.h2`
  text-align: center;
  color: rgb(4, 201, 34);

  ${responsive({
    fontSize: "20px",
  })}
`;

const TextWrappeer = styled.div`
  display: flex;
  margin: 10px 5px;
  ${responsive({
    // fontSize: "14px",
  })}
`;

const Text = styled.div`
  ${responsive({
    // fontSize: "14px",
  })}
`;

const IconWrapper = styled.div`
  color: rgb(33, 4, 97);
  margin: 0px 10px;
`;

const Center = styled.div`
  flex: 1;
  background-color: rgb(237, 228, 228);
  border-radius: 10px;
  padding: 10px 0px;
  margin: 20px 30px;
  ${responsive({
    margin: "5px",
  })}
`;

const Right = styled.div`
  flex: 1;
  background-color: rgb(237, 228, 228);
  border-radius: 10px;
  padding: 10px 0px;
  margin: 20px 10px;

  ${responsive({
    margin: "5px",
  })}
`;

const FacilitiesComponent = () => {
  AnimationOnScroll();
  return (
    <Container>
      <Top>
        <Img src={TopImg} />
      </Top>

      <Bottom>
        <Heading data-aos="fade-up" data-aos-duration="2000">
          HOTEL AMENITIES & FACILITIES
        </Heading>

        <Wrapper>
          <Left>
            <HeadingText data-aos="fade-up" data-aos-duration="2000">
              Room Facility
            </HeadingText>
            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Fully air-Conditioned rooms & suites</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Electronic Safes</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Mini bar & Hair dryer in all rooms</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Flat screen TV with 90 channels</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Safe deposit box</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Iron & iron board(on call)</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Tea/Coffee making Facility in the room</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Smoking & Non-Smoking rooms</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>IDD Telephone</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Hot & Cold Water</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>24-hour room service</Text>
            </TextWrappeer>
          </Left>

          <Center>
            <HeadingText data-aos="fade-up" data-aos-duration="2000">
              Complimentary Services
            </HeadingText>
            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Fully air-Conditioned rooms & suites</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Electronic Safes</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Mini bar & Hair dryer in all rooms</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Flat screen TV with 90 channels</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Safe deposit box</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Iron & iron board(on call)</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Tea/Coffee making Facility in the room</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Smoking & Non-Smoking rooms</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>IDD Telephone</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Hot & Cold Water</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>24-hour room service</Text>
            </TextWrappeer>
          </Center>
          <Right>
            <HeadingText data-aos="fade-up" data-aos-duration="2000">
              General Facilities
            </HeadingText>
            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Fully air-Conditioned rooms & suites</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Electronic Safes</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Mini bar & Hair dryer in all rooms</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Flat screen TV with 90 channels</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Safe deposit box</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Iron & iron board(on call)</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Tea/Coffee making Facility in the room</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Smoking & Non-Smoking rooms</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>IDD Telephone</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>Hot & Cold Water</Text>
            </TextWrappeer>

            <TextWrappeer>
              <IconWrapper>
                <BsFillArrowRightCircleFill />
              </IconWrapper>
              <Text>24-hour room service</Text>
            </TextWrappeer>
          </Right>
        </Wrapper>
      </Bottom>
    </Container>
  );
};

export default FacilitiesComponent;
